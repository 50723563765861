import React from "react";

// Styles
import './TableFilters.css'

function TableFilterDatePicker({ onChange }: TableFilterDatePickerProps) {
    return (
        <input
            type="date"
            className="table-filters-date-box"
            onChange={(e) => onChange(e)}
        />
    )
}

export default TableFilterDatePicker;

interface TableFilterDatePickerProps {
    onChange: any
}