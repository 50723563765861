import React, { useState, useEffect } from 'react'
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useLocation } from 'react-router-dom';
import { API_Endpoint } from '../../../../Configuration/Settings';
import axios from 'axios';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import Loader from '../../../../Components/Loader/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { Pagination, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./MasterFlow.css"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function DetailMasterFlow() {
    const Location = useLocation()
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState(Location.state.menuItem)
    const [itemId, setItemId] = useState(Location.state.id)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [masterData, setMasterData] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const Navigate = useNavigate()

    const handleSelectedOption = (event: SelectChangeEvent, id: string) => {
        setSelectedOption(event.target.value as string);
        setItemId(id)
    };

    useEffect(() => {
        setLoading(true);
        let payload = {
            token: encryptData({
                _id: itemId,
                role: selectedOption,
                itemsPerPage: itemsPerPage,
                pageNo: currentPage
            })
        }
        const token = localStorage.getItem("token");
        axios
            .post(
                `${API_Endpoint}/masterFlow/getUsersList`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setMasterData(response.data.data.payload.items)
                setTotalPages(response.data.data.payload.totalPages)
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }, [selectedOption, itemId, currentPage])

    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={`${selectedOption} Details`} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Master Name</th>
                                                    <th>Mobile</th>
                                                    <th>Credit Limit</th>
                                                    <th>Available Balance</th>
                                                    <th>Exposer</th>
                                                    <th>P/L</th>
                                                    <th>Partnership</th>
                                                    <th>status</th>
                                                    <th>Action</th>
                                                    {Location.state.menuItem === "player" ? "" : <th></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {masterData.map((item: any, index) => (
                                                    <tr id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.initialWalletBalance}</td>
                                                        <td>{item.currentWalletBalance}</td>
                                                        <td>{item.exposer ?? 0}</td>
                                                        <td>
                                                            <span className={(item.pnl) > 0 ? "green" : (item.pnl) < 0 ? "red" : ""}>
                                                                <b>{(item.pnl)?.toFixed(2) ?? 0}</b>
                                                            </span>
                                                        </td>
                                                        <td>{item.ownShare}</td>
                                                        <td>{item.isActive === true ? "true" : "false"}</td>
                                                        <td>
                                                            <FontAwesomeIcon
                                                                id={item._id}
                                                                className="fa fa-pencil-square icon-home icon-trash "
                                                                icon={faList}
                                                                onClick={() => {
                                                                    const url = `/user-report-master/${item._id}/${item.name}`;
                                                                    Navigate(url);
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                        {Location.state.menuItem === "player" ? "" : <td>
                                                            <div className="withdraw-select">
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                                                    {Location.state.menuItem === 'superAdmin' && (
                                                                        <>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedOption}
                                                                                label="Status"
                                                                                onChange={(event) => handleSelectedOption(event, item._id)}
                                                                            >
                                                                                <MenuItem value={"admin"}>Admin</MenuItem>
                                                                                <MenuItem value={"subAdmin"}>Sub Admin</MenuItem>
                                                                                <MenuItem value={"superMaster"}>Super Master</MenuItem>
                                                                                <MenuItem value={"master"}>Master</MenuItem>
                                                                                <MenuItem value={"player"}>Player</MenuItem>
                                                                            </Select>
                                                                        </>
                                                                    )}
                                                                    {Location.state.menuItem === 'admin' && (
                                                                        <>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedOption}
                                                                                label="Status"
                                                                                onChange={(event) => handleSelectedOption(event, item._id)}
                                                                            >
                                                                                <MenuItem value={"subAdmin"}>Sub Admin</MenuItem>
                                                                                <MenuItem value={"superMaster"}>Super Master</MenuItem>
                                                                                <MenuItem value={"master"}>Master</MenuItem>
                                                                                <MenuItem value={"player"}>Player</MenuItem>
                                                                            </Select>
                                                                        </>
                                                                    )}
                                                                    {Location.state.menuItem === 'subAdmin' && (
                                                                        <>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedOption}
                                                                                label="Status"
                                                                                onChange={(event) => handleSelectedOption(event, item._id)}
                                                                            >
                                                                                <MenuItem value={"superMaster"}>Super Master</MenuItem>
                                                                                <MenuItem value={"master"}>Master</MenuItem>
                                                                                <MenuItem value={"player"}>Player</MenuItem>
                                                                            </Select>
                                                                        </>
                                                                    )}
                                                                    {Location.state.menuItem === 'superMaster' && (
                                                                        <>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedOption}
                                                                                label="Status"
                                                                                onChange={(event) => handleSelectedOption(event, item._id)}
                                                                            >
                                                                                <MenuItem value={"master"}>Master</MenuItem>
                                                                                <MenuItem value={"player"}>Player</MenuItem>
                                                                            </Select>
                                                                        </>
                                                                    )}

                                                                    {Location.state.menuItem === 'master' && (
                                                                        <>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedOption}
                                                                                label="Status"
                                                                                onChange={(event) => handleSelectedOption(event, item._id)}
                                                                            >
                                                                                <MenuItem value={"player"}>Player</MenuItem>
                                                                            </Select>
                                                                        </>
                                                                    )}
                                                                </FormControl>
                                                            </div>
                                                        </td>}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={currentPage}
                                                onChange={(event, newPage) => setCurrentPage(newPage)}
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default DetailMasterFlow