import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import { formatDate } from '../../utils/utility'
import Stateful_Select from '../Dropdown/Dropdown'


interface Deposit {
    _id: string,
    name: string,
    mobile: string,
    balance: number,
    createdOn: string,
    updatedOn: string
}
function UserDepositCoinTable() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [depositData, setDepositData] = useState<Deposit[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");

    const formatedDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };
    const formattedDate = (timestamp: any) => formatedDate(new Date(timestamp));

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    useEffect(() => {
        let url
        if (customProps.type === "nonperform") {
            url = `${API_Endpoint}/User/nonPerformingUser`
        } else {
            url = `${API_Endpoint}/User/depositUser`
        }
        let data = customProps
        data.pageNo = currentPage
        data.itemPerPage = parseInt(itemsPerPage)
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setDepositData(response.data.data.payload.items)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }, [currentPage, itemsPerPage])

    return (
        <div className="g-sidenav-show  bg-gray-100">
            <Sidenav />
            <main className="main-content position-relative">
                <div style={{ background: "#f8f9fa" }}>
                    <Breadcrumbs tab={customProps.type === "old" ? "Old Deposits Approved" : "New Deposits Approved"} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="row tp-form mb-2">
                                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                    <label className="lbl">Items Per Page</label>
                                    <div className="mt-1">
                                        <Stateful_Select
                                            value={itemsPerPage}
                                            onChange={(newValue: any) => handlePerPage(newValue)}
                                            options={["10", "25", "50", "75", "100"]}
                                            className="deposit-select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-2">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>User Name</th>
                                                <th>Mobile No</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {depositData.map((item, index) => (
                                                <tr id={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td onClick={() => {
                                                        const url = `/user-report/${item._id}/${item.name}`;
                                                        window.open(url, "_blank");
                                                    }} style={{ cursor: 'pointer' }}>{item.name}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{Math.round(item.balance)}</td>
                                                    <td>{formattedDate(item.createdOn)}</td>
                                                    <td>{formatedTime(item.createdOn)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination d-flex justify-content-center">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPages}
                                            color="secondary"
                                            page={currentPage}
                                            onChange={(Event, New_Page) =>
                                                setCurrentPage(New_Page)
                                            }
                                        />
                                    </Stack>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default UserDepositCoinTable