import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { API_Endpoint } from "../../Configuration/Settings";
import { User_Context } from "../../Contexts/User";
import { useParams } from "react-router-dom";
import { decryptData } from "../../utils/decryptData";
import { encryptData } from "../../utils/encryptData";

// Components
import Sidenav from "../../Components/SideNavigation/SideNavigation";
import Loader from "../../Components/Loader/Loader";

// Views
import UserReportData from "./Views/UserReportData/UserReportData";
import WalletHistory from "./Views/WalletHistory/WalletHistory";
import GameHistory from "./Views/GameHistory/GameHistory";
import UserReportButtons from "../../Components/UserReportButtons/UserReportButtons";
import Coin from "../../Components/Coins/Coin";
import WorliHistory from "./Views/WorliHistory/WorliHistory";
import WacHistory from "./Views/WacHistory/WacHistory";
import ScannerDeposit from "./Views/ScannerDeposit/ScannerDeposit";
import BonusWalletCoins from "../../Components/BonusWalletCoins/BonusWalletCoins";

// Styles
import './User_Report.css'

interface User_Report_Data {
	_id: string,
	pendingWithdrawals: any[],
	pendingWithdrawal_sum: number,
	profit_and_loss: number,
	exposure: number,
	balance: number,
	betHistory: any[],
	userGgr: number
}

export default function User_Report() {
	const [Financial_Report, Set_Financial_Report] = useState<User_Report_Data>({
		_id: '',
		pendingWithdrawals: [],
		pendingWithdrawal_sum: 0,
		profit_and_loss: 0,
		exposure: 0,
		balance: 0,
		betHistory: [],
		userGgr: 0
	});
	const [loading, setLoading] = useState<boolean>(false);
	const { User_ID, userName } = useParams();

	const { User } = useContext(User_Context)

	const [selectedComponent, setSelectedComponent] = useState('wallet_history')

	// useEffect(() => {
	// 	setLoading(true);
	// 	let config = {
	// 		method: "post",
	// 		maxBodyLength: Infinity,
	// 		url: `${API_Endpoint}/User/userProfitLoss`,
	// 		headers: { Authorization: `Bearer ${User.token}` },
	// 		data: { token: encryptData({ _id: User_ID }) },
	// 	};
	// 	axios.request(config).then((response) => {
	// 		response.data.data = decryptData(response.data.data);
	// 		Set_Financial_Report(response.data.data.payload);
	// 		setLoading(false);
	// 	}).catch((error) => { console.log(error); });
	// }, []);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="g-sidenav-show  bg-gray-100">
					<ToastContainer autoClose={2000} position="top-center" />
					<Sidenav />
					<main className="main-content position-relative">
						<div style={{ background: "#f8f9fa" }}>
							<div className="container-fluid py-1 px-3">
								<nav aria-label="breadcrumb" className="breadcrumbs">
									<ol className="breadcrumb bg-transparent mb-0 px-0">
										<li className="breadcrumb-item">
											<span className="opacity-5 text-dark">User :</span>
										</li>
										<li className="breadcrumb-item active text-dark" aria-current="page">{userName}</li>
									</ol>
								</nav>
							</div>
							<div className="container-fluid">
								<div className="user-detail-button-container">
									<UserReportButtons title={'Wallet History'} onClick={() => setSelectedComponent('wallet_history')} />
									<UserReportButtons title={'User Report'} onClick={() => setSelectedComponent('user_report')} />
									{/* <UserReportButtons title={'Game History'} onClick={() => setSelectedComponent('game_history')} /> */}
									<UserReportButtons title={'Starline History'} onClick={() => setSelectedComponent('starline_history')} />
									<UserReportButtons title={'King Bazar History'} onClick={() => setSelectedComponent('king_bazar_history')} />
									<UserReportButtons title={'Wac History'} onClick={() => setSelectedComponent('wac_history')} />
									{/* <UserReportButtons title={'Fund Request'} /> */}
									{/* <UserReportButtons title={'PNL'} /> */}
									<UserReportButtons title={'Coins'} onClick={() => setSelectedComponent('coins')} />
									{/* <UserReportButtons title={'Worli History'} onClick={() => setSelectedComponent('worli_history')} /> */}
									{/* <UserReportButtons title={'Scanner Deposit'} onClick={() => setSelectedComponent('scanner_deposit')} /> */}
									{/* <UserReportButtons title={'Exchange History'} /> */}
									{/* <UserReportButtons title={'User Apps'} /> */}
									<UserReportButtons title={'Bonus Coins'} onClick={() => setSelectedComponent('bonus_coins')} />
								</div>

								{selectedComponent === "bonus_coins" &&
									<BonusWalletCoins />
								}

								{selectedComponent === "user_report" &&
									<UserReportData
										data={Financial_Report}
									/>
								}
								{selectedComponent === "scanner_deposit" &&
									<ScannerDeposit
									/>
								}
								{selectedComponent === "wac_history" &&
									<WacHistory
									/>
								}
								{selectedComponent === "coins" &&
									<Coin />
								}


								{selectedComponent === "wallet_history" &&
									<WalletHistory
										type={'wallet'}
									/>
								}

								{selectedComponent === "game_history" &&
									<GameHistory
									/>
								}

								{selectedComponent === "starline_history" &&
									<WalletHistory
										type={'starline'}
									/>
								}

								{selectedComponent === "king_bazar_history" &&
									<WalletHistory
										type={'king_bazar'}
									/>
								}


								{selectedComponent === "worli_history" &&
									<WorliHistory
									/>
								}
							</div>
							<footer className="footer">
								<div className="container-fluid">
									<div className="row align-items-center justify-content-lg-between">
										<div className="col-lg-6 mb-lg-0  ">
											<div className="copyright text-center text-sm text-muted text-lg-start">
												© 2024 kuberbets.vip
											</div>
										</div>
										<div className="col-lg-6">
											<ul className="nav nav-footer justify-content-center justify-content-lg-end">
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														Home
													</a>
												</li>
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														
													</a>
												</li>
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link pe-0 text-muted"
														target="_blank"
													>
														
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</main>
				</div>
			)}
		</>
	);
}