import React, { useState, useEffect } from 'react';
import { formatDate, formatedTime } from '../../../../utils/utility';
import { Pagination, Stack } from "@mui/material";

// Css

import "./WalletHistory.css"

// Components
import TableFiltersSearchBox from '../../../../Components/TableFilters/TableFiltersSearchBox';
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox';
import TableFilterDatePicker from '../../../../Components/TableFilters/TableFilterDatePicker';

function WalletHistoryTable({ data, title, currentPage, setCurrentPage, totalPages, itemsPerPage }: WalletHistoryTableProps) {

    const [tableData, setTableData] = useState(data);
    const [searchAmount, setSearchAmount] = useState('');
    const [searchLastBalance, setSearchLastBalance] = useState('');

    const [openingBalance, setOpeningBalance] = useState('');

    const typeArray = [{ id: 'CR', value: 'Credited' }, { id: 'DR', value: 'Debited' }]
    const [selectedType, setSelectedType] = useState('');

    const paymentMedium = [{ id: "AB-UPI", value: "AB-UPI" }, { id: 'A2Z', value: 'A2Z' }]
    const [selectedMedium, setSelectedMedium] = useState('');

    const [searchAdminAllot, setSearchAdminAllot] = useState('');

    const actionTypeArray = [{ id: 'Deposit', value: 'Deposit' }, { id: 'withdrawal request', value: 'Withdrawal' }, { id: 'Settled', value: 'Settled' }, { id: 'Resettled', value: 'Resettled' }, { id: 'Place Bet', value: 'Place Bet' }, , { id: 'cancelled', value: 'cancelled' }]
    const [actionType, setActionType] = useState('')

    const onSearchAmount = (values: any) => {
        setSearchAmount(values);

        const filteredData = data.filter(
            (item: any) => item.amount.toString().includes(values)
        );
        setTableData(filteredData);
    }

    const onSearchLastBalance = (values: any) => {
        setSearchLastBalance(values);

        const filteredData = data.filter(
            (item: any) => item.balance.toString().includes(values)
        );
        setTableData(filteredData);
    }
    const onSearchOpeningBalance = (values: any) => {
        setOpeningBalance(values);

        const filteredData = data.filter(
            (item: any) => item.lastBalance.toString().includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeType = (values: any) => {
        setSelectedType(values);
        const filteredData = data.filter(
            (item: any) => item.transactionType.includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeMedium = (values: any) => {
        setSelectedMedium(values);
        const filteredData = data.filter(
            (item: any) => item.providerName.includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeAction = (values: any) => {
        setActionType(values);
        const filteredData = data.filter(
            (item: any) => item.action.includes(values)
        );
        setTableData(filteredData);
    }

    // const onSearchAdminAllot = (values: any) => {
    //     setSearchAdminAllot(values);

    //     const filteredData = data.filter(
    //         (item: any) => item.amount.toString().includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    const onDateChange = (values: any) => {
        if (values) {
            const filteredData = data.filter(
                (item: any) => formatDate(item.createdOn) === formatDate(values)
            );
            if (filteredData.length > 0) {
                setTableData(filteredData);
            }

        }

    }

    const onUpdatedDateChange = (values: any) => {
        if (values) {
            const filteredData = data.filter(
                (item: any) => formatDate(item.updatedOn) === formatDate(values)
            );
            if (filteredData.length > 0) {
                setTableData(filteredData);
            } else {
                setTableData(data)
            }

        } else {
            setTableData(data)
        }

    }

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const getBackgroundStyle = (action: any) => {
        switch (action) {
            case "Win":
                return "rgb(255 0 149 / 22%)";
            case "Deposit":
                return "rgba(255, 255, 0, 0.36)";
            case "Settled":
                return "rgb(255 0 149 / 22%)";
            case "withdrawal request":
                return "rgb(0 225 21 / 31%)";
            default:
                return "";
        }
    };
    const getFontSizeStyle = (action: any) => {
        switch (action) {
            case "Win":
                return "15px";
            case "Deposit":
                return "15px";
            case "Settled":
                return "15px";
            case "withdrawal request":
                return "15px";
            default:
                return "14px";
        }
    };
    const getFontWeightStyle = (action: any) => {
        switch (action) {
            case "Win":
                return "700";
            case "Deposit":
                return "700";
            case "Settled":
                return "700";
            case "withdrawal request":
                return "700";
            default:
                return "500";
        }
    };


    return (
        <div className="row">
            {title && <h2>{title}</h2>}
            <div className="table-responsive">
                <table className="table table-view table-wallet">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Provider</th>
                            <th>Action</th>
                            <th>Detail</th>
                            <th>Commission Amount</th>
                            <th>Type</th>
                            <th>Opening Balance</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Created On</th>
                            {/* <th>Updated On</th> */}
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <TableFiltersSearchBox
                                    value={selectedMedium}
                                    placeholder='Search By Provider Name'
                                    onChange={(e: any) => onChangeMedium(e.target.value)}
                                />
                            </th>

                            <th>
                                <TableFiltersSelectBox
                                    value={actionType}
                                    options={actionTypeArray}
                                    onChange={(e: any) => onChangeAction(e.target.value)}
                                />
                            </th>
                            <th>
                                <TableFiltersSearchBox
                                    value={searchLastBalance}
                                    placeholder=''
                                    onChange={(e: any) => onSearchOpeningBalance(e.target.value)}
                                    disabled={true}
                                />
                            </th>
                            <th></th>

                            <th>
                                <TableFiltersSelectBox
                                    value={selectedType}
                                    options={typeArray}
                                    onChange={(e: any) => onChangeType(e.target.value)}
                                />
                            </th>
                            <th>
                                <TableFiltersSearchBox
                                    value={searchLastBalance}
                                    placeholder='Search Opening Balance'
                                    onChange={(e: any) => onSearchOpeningBalance(e.target.value)}
                                />
                            </th>
                            <th>
                                <TableFiltersSearchBox
                                    value={searchAmount}
                                    placeholder='Search By Amount'
                                    onChange={(e: any) => onSearchAmount(e.target.value)}
                                />
                            </th>
                            <th>
                                <TableFiltersSearchBox
                                    value={searchLastBalance}
                                    placeholder='Search Closing Balance'
                                    onChange={(e: any) => onSearchLastBalance(e.target.value)}
                                />
                            </th>
                            <th>
                                <TableFilterDatePicker
                                    onChange={(e: any) => onDateChange(new Date(e.target.value))}
                                />
                            </th>
                            {/* <th>
                                <TableFilterDatePicker
                                    onChange={(e: any) => onUpdatedDateChange(new Date(e.target.value))}
                                />
                            </th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((item: any, index: number) =>
                            <tr style={{
                                background: getBackgroundStyle(item.action)
                            }} key={item._id}>
                                <td>{index + 1 + (currentPage && itemsPerPage  ? (currentPage - 1)  * itemsPerPage : 0)}</td>
                                <td>{item.providerName}</td>
                                <td>{item.action}</td>
                                <td>
                                    {item.description?.eventName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>EventName: </span>
                                            <span className="wallet-color">{`${item.description.eventName} `}</span>
                                            <br />
                                        </>

                                    )}
                                    {item.description?.marketName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>MarketName: </span>
                                            <span className="wallet-color"> <b>{`${item.description.marketName} `}</b></span>
                                            <br />
                                        </>

                                    )}

                                    {item.description?.transactionId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>TrasactionId: </span>
                                            <span className="wallet-color">{`${item.description.transactionId} `}</span>
                                        </>
                                    )}

                                    {item.description?.marketId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>MarketId: </span>
                                            <span className="wallet-color">{`${item.description.marketId} `}</span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.rate && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Rate: </span>
                                            <span className="wallet-color">{`${item.description.rate} `}</span>
                                        </>
                                    )}

                                    {item.description?.betType && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>BetType: </span>
                                            <span className="wallet-color">{`${item.description.betType} `}</span>
                                        </>
                                    )}

                                    {item.description?.game && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>BetOn: </span>
                                            <span className="wallet-color"><b style={{ fontSize: "15px" }}>{`${item.description.game} `}</b></span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.paymentGatewayName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>GatewayName: </span>
                                            <span className="wallet-color">{`${item.description.paymentGatewayName} `}</span>
                                        </>
                                    )}

                                    {item.description?.paymentType && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>PaymentType: </span>
                                            <span className="wallet-color">{`${item.description.paymentType} `}</span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.reason && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Reason: </span>
                                            <span className="wallet-color">{`${item.description.reason} `}</span>
                                        </>
                                    )}

                                    {item.description?.remark && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Remark: </span>
                                            <span className="wallet-color">{`${item.description.remark} `}</span>
                                        </>
                                    )}
                                    {item.description?.stake && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Stake: </span>
                                            <span className="wallet-color">{`${item.description.stake} `}</span>
                                        </>
                                    )}
                                    {item.description?.runnerName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>RunnerName: </span>
                                            <span className="wallet-color">{`${item.description.runnerName} `}</span>
                                        </>
                                    )}
                                    {item.description?.run && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Run: </span>
                                            <span className="wallet-color">{`${item.description.run} `}</span>
                                        </>
                                    )}
                                    {item.description?.updatedBy?.name && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>UpdatedBy: </span>
                                            <span className="wallet-color">{`${item.description.updatedBy.name} `}</span>
                                        </>
                                    )}
                                    {item.description?.gameReference && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>GameName: </span>
                                            <span className="wallet-color">{`${item.description?.gameReference} `}</span>
                                        </>
                                    )}
                                    {item.description?.settlementId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>SettlementId: </span>
                                            <span className="wallet-color">{`${item.description?.settlementId} `}</span>
                                        </>
                                    )}
                                    {item.description?.category && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Category: </span>
                                            <span className="wallet-color">{`${item.description?.category} `}</span>
                                        </>
                                    )}
                                </td>
                                {/* <td>{item?.description?.commissionAmount}</td> */}
                                <td>{item.providerName === "Qtech" ? item?.description?.commission : item?.description?.commissionAmount}</td>
                                <td>{`${item.transactionType === "CR" ? "Credited" : "Debited"}`}</td>
                                <td>{Math.round(item.lastBalance)}</td>
                                <td style={{
                                    fontSize: getFontSizeStyle(item.action), fontWeight: getFontWeightStyle(item.action)
                                }}>{item.amount}</td>
                                <td>{Math.round(item.balance)}</td>
                                <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                {/* <td>{`${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}`}</td> */}
                            </tr>)}
                    </tbody>
                </table>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={totalPages}
                            color="secondary"
                            page={currentPage}
                            onChange={(Event, New_Page) =>
                                setCurrentPage(New_Page)
                            }
                        />
                    </Stack>
                </div>

            </div>
        </div>
    )
}

export default WalletHistoryTable;

interface WalletHistoryTableProps {
    data?: any,
    title?: String,
    currentPage?: number,
    setCurrentPage?: any,
    totalPages?: any,
    itemsPerPage?: number
}