import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { User_Context } from '../../../../Contexts/User';
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { useNavigate } from "react-router-dom";


// Components
import Loader from '../../../../Components/Loader/Loader';
import WalletHistoryMasterTable from './WalletHistoryMasterTable';

// Styles
import './WalletHistory.css'
import Stateful_Select from '../../../../Components/Dropdown/Dropdown';
import Reusable_Input from '../../../../Components/InputField/InputField';
import { Button } from '@mui/material';

interface BonusWalletData {
    userReferral: number;
    userReferralCount: number;
    userOwnEarning: number;
    userOwnEarningCount: number;
}

interface WalletHistoryProps {
    type?: String;
}

function WalletHistoryMaster({ type }: WalletHistoryProps) {
    const [loading, setLoading] = useState(false)
    const { User } = useContext(User_Context)
    const { User_ID } = useParams();

    const [historyData, setHistoryData] = useState([]);
    const [pageNo, setPageNo] = useState(1)

    const [depositTotal, setDepositTotal] = useState(0);
    const [withdrawalTotal, setWithdrawalTotal] = useState(0);

    const [balanceTotal, setBalanceTotal] = useState(0);
    const [bonusBalanceTotal, setBonusBalanceTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [marketIdSearchKey, setMarketIdSearchKey] = useState('');
    const [bonusData, setBonusData] = useState<BonusWalletData>()
    const Navigate = useNavigate();


    const getWalletHistory = async (page: number) => {
        setLoading(true);
        let payload: { itemsPerPage: number, pageNo: number, filter: { [key: string]: string | number } } = {
            itemsPerPage: Number(itemsPerPage),
            pageNo: page,
            filter: {
                userId: User_ID?.toString() ?? '',
            }
        }
        if (marketIdSearchKey) {
            payload.filter["description.marketId"] = Number(marketIdSearchKey);
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            // url: `${API_Endpoint}/wallet-History`,
            url:`${API_Endpoint}/masterFlow/getStatement`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(payload) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const dataArray = response.data.data.payload
            setHistoryData(dataArray.items);
            setDepositTotal(dataArray.totalDeposit);
            setWithdrawalTotal(dataArray.totalWithdrawal);
            setBalanceTotal(Math.floor(dataArray.balance));
            setBonusBalanceTotal(dataArray.bonusWalletBalance);
            setTotalPages(dataArray.totalPages);
            setLoading(false);
        }).catch((error) => { console.log(error); });
    }


    useEffect(() => {
        getWalletHistory(pageNo);
    }, [pageNo, itemsPerPage]);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let data = {
            userId: User_ID,
            itemsPerPage: 10,
            pageNo: 1
        }
        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/user-total-earning`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setBonusData(response.data.data.payload)
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err.message);
                });
        }
    }, []);


    const handleSubmitClick = () => getWalletHistory(pageNo);
  return (
    <div>
            {loading && <Loader />}
            <div className="row main-div">
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl">Items Per Page</label>
                    <Stateful_Select
                        value={itemsPerPage.toString()}
                        onChange={(newValue: any) => setItemsPerPage(newValue)}
                        options={["50", "75", "100", "125", "150", "175", "200"]}
                    />
                </div>
                {/* <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl m-1">Market ID</label>
                    <Reusable_Input
                        label={""}
                        value={marketIdSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setMarketIdSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        customInputTextStyles={{
                            padding: '6px',
                        }}
                    />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web d-flex align-items-end submit-wallet">
                    <Button
                        onClick={handleSubmitClick}
                        className="sech-button px-2 py-1"
                        variant="contained"
                        disabled={!marketIdSearchKey?.length}
                    >
                        Submit
                    </Button>
                </div> */}
            </div>

            {!loading &&
                <WalletHistoryMasterTable
                    data={historyData}
                    currentPage={pageNo}
                    totalPages={totalPages}
                    setCurrentPage={(value: number) => setPageNo(value)}
                    itemsPerPage={itemsPerPage}
                />
            }
        </div>
  )
}

export default WalletHistoryMaster