import React from 'react';

function GameHistory({ data, title }: GameHistoryProps) {
    return (
        <div className="row mt-4">
            {title && <h2>{title}</h2>}
            <div className="table-responsive">
                <table className="table table-view">
                    <thead>
                        <tr>
                            <th>Bazar Name</th>
                            <th>Game Type</th>
                            <th>Game Name</th>
                            <th>Game</th>
                            <th>Game Date</th>
                            <th>Betting Time</th>
                            <th>Wallet Time</th>
                            <th>Point</th>
                            <th>Status</th>
                            <th>Winning Point</th>
                            <th>Commission</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data.pendingWithdrawals.map(Pending_Withdrawal =>
													<tr key={Pending_Withdrawal._id}>
														<td>{Pending_Withdrawal._id}</td>
														<td>{Pending_Withdrawal.dp_id}</td>
														<td>{Pending_Withdrawal.amount}</td>
														<td>{Pending_Withdrawal.ifscCode}</td>
														<td>{Pending_Withdrawal.mobile}</td>
														<td>{Pending_Withdrawal.accountNo}</td>
														<td>{Pending_Withdrawal.accountHolderName}</td>
														<td>{Pending_Withdrawal.transactionId}</td>
														<td>{Pending_Withdrawal.status}</td>
														<td>{formatDate(Pending_Withdrawal.createdOn)}</td>
														<td>{formatDate(Pending_Withdrawal.updatedOn)}</td>
													</tr>)} */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default GameHistory

interface GameHistoryProps {
    data?: any,
    title?: String
}