import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useContext,
  useMemo,
  SyntheticEvent,
  useRef,
  useCallback,
} from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "./Banners.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";

interface Images {
  _id: string;
  imagePath: string; //Blob | MediaSource;
  imageKey: string;
  iframeUrl: string;
  status: boolean;
  type: string;
  gameName: string;
}

const initialDescriptionData = {
  titleError: "",
  subtitleError: "",
  titleHelperText: "",
  subTitleHelperText: "",
};

function Banners() {
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [linkError, setLinkError] = useState<boolean>(false);
  const [linkHelperText, setLinkHelperText] = useState<string>("");
  const [selectedFileHelperText, setSelectedFileLinkHelperText] =
    useState<string>("");
  const [mobLink, setMobLink] = useState<string>("");
  const [mobError, setMobError] = useState<boolean>(false);
  const [mobHelperText, setMobHelperText] = useState<string>("");
  const [gameName, setGameName] = useState<string>("");
  const [gameNameError, setGameNameError] = useState<boolean>(false);
  const [gameNameHelperText, setGameNameHelperText] = useState<string>("");
  const [selectedFileError, setSelectedFileError] = useState<boolean>(false);
  const [typeHelperText, setTypeHelperText] = useState<string>("");
  const [typeError, setTypeError] = useState<boolean>(false);
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [categoryHelperText, setCategoryHelperText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [images, setImages] = useState<Array<Images>>([]);
  const [imageId, setImageId] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const { User } = useContext(User_Context);
  const [File_Name, Set_File_Name] = useState<string>("");
  const [Image, Set_Image] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<{
    titleError: string;
    subtitleError: string;
  }>(initialDescriptionData);
  const [description, setDescription] = useState<{
    title: string;
    subTitle: string;
  }>({ title: "", subTitle: "" });

  const [mobileRoute, setMobileRoute] = useState<string>("");
  const [mobileParams, setMobileParams] = useState<string>("");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const reader = new FileReader();
      const File_Object = event.target.files[0];
      reader.readAsDataURL(File_Object);
      reader.onload = () => {
        Set_File_Name(File_Object.name);
        Set_Image(reader.result?.toString() as string);
      };
    }
  };

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setUrl(event.target.value);
    setLinkError(false);
  };

  const handleMobChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMobLink(event.target.value);
    setMobError(false);
  };

  const handleGameNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setGameName(event.target.value);
    setGameNameError(false);
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
    setTypeError(false);
  };

  const handleDropdownChangeMobilePage = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setMobileRoute(event.target.value);
    setTypeError(false);
  };

  const handleDropdownChangeMobileParams = (
    event: SelectChangeEvent<string>
  ) => {
    event.preventDefault();
    setMobileParams(event.target.value);
    setTypeError(false);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedCategory(event.target.value);
    setCategoryError(false);
  };

  // let imageId: string = '111111111111'
  const [openDialogForImage, setOpenDialogForImage] = useState<boolean>(false);

  const getAllBanners = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `${API_Endpoint}/bannerGames/getAll`,
        { token: encryptData({}) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        response.data.data = decryptData(response.data.data);
        const imageArr = response.data.data.payload.map(
          async (item: Images) => {
            return {
              ...item,
              imagePath: `${item.imagePath}`,
            };
          }
        );
        Promise.all(imageArr).then((item) => setImages(item));
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error.message);
      });
  };
  useEffect(() => {
    getAllBanners();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const openPopup = (id: string) => {
    setDeletePopup(true);
    setImageId(id);
  };
  const deleteImage = () => {
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${API_Endpoint}/bannerGames/delete`,
          { token: encryptData({ _id: imageId }) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          const updatedImageStatus = images.filter(
            (item) => item._id !== imageId
          );
          setImages(updatedImageStatus);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error ", error);
        });
    }
  };

  // const handleAddSwitchChange = (e) => {
  //   setSwitchAddState(e.target.checked)
  // }
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const checked = event.target.checked;
    const id = event.target.id;
    const apiUrl = `${API_Endpoint}/bannerGames`;
    const postData = {
      _id: id,
      status: checked,
    };
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${apiUrl}/update`,
          { token: encryptData(postData) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const updatedImageStatus = images.map((item) => {
            if (item._id == id) {
              return {
                ...item,
                status: checked,
              };
            } else return item;
          });
          setImages(updatedImageStatus);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!Image) {
   
      setSelectedFileError(true);
      setSelectedFileLinkHelperText("Choose file");
    } else if (!url) {
 
      setLinkError(true);
      setLinkHelperText("Enter desktop link");
    }
    // else if (!mobLink) {
    //   setMobError(true);
    //   setMobHelperText("Enter mobile link");
    // }
    else if (!gameName) {
      
      setGameNameError(true);
      setGameNameHelperText("Enter game name");
    } else if (!selectedOption) {
     
      setTypeError(true);
      setTypeHelperText("Select type");
    } else if (!selectedCategory) {
    
      setCategoryError(true);
      setCategoryHelperText("Select category");
    } 
    // else if (!description.title) {
    //   console.log("16");
    //   setDescriptionError({
    //     ...descriptionError,
    //     titleError: "Enter Banner Title",
    //   });
    // } else if (!description.subTitle) {
    //   console.log("17");
    //   setDescriptionError({
    //     ...descriptionError,
    //     subtitleError: "Enter Banner Subtitle",
    //   });
    // } 
    else {
      
      setLoading(true);
      let payload: {[key: string]: string | boolean | number | {[key: string]: string}} = {
        iframeUrlMob: mobileRoute,
        iframeUrl: url,
        type: selectedOption,
        File_Name: File_Name,
        Image: Image,
        gameName: gameName,
        category: selectedCategory,
        deepLink: true,
        mobileRouter: mobileRoute,
        mobileOptions: mobileParams,
      };
      const {title, subTitle} = description;
      if(selectedOption === 'bonusScreenBanners' && title && subTitle){
        payload['decryption'] = description
      }
      axios
        .post(
          `${API_Endpoint}/bannerGames/create`,
          { token: encryptData(payload) },
          {
            headers: {
              Authorization: `Bearer ${User.token}`,
            },
          }
        )
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          setImages(response.data.data.payload);
          getAllBanners();
          setOpenDialogForImage(false);
          setLoading(false);
        })
        .catch((err: any) => {
          setOpenDialogForImage(false);
          setLoading(false);
          console.log(`${err.message}`);
        });
    }
  };
  const label = useMemo(() => {
    return { inputProps: { "aria-label": "Switch demo" } };
  }, []);

  const handleBannerTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || "";
      if (value.length) {
        setDescriptionError({ ...descriptionError, titleError: "" });
      }
      setDescription({ ...description, title: event.target.value });
    },
    [description, descriptionError]
  );

  const handleBannerSubTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || "";
      if (value.length) {
        setDescriptionError({ ...descriptionError, subtitleError: "" });
      }
      setDescription({ ...description, subTitle: event.target.value });
    },
    [description, descriptionError]
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs
                tab={"Banners List"}
                button={
                  User.data.Responsibilities.includes(
                    Responsibilities.Add_Banner
                  ) && (
                    <Button
                      onClick={() => setOpenDialogForImage(true)}
                      className="btn-payment"
                      variant="contained"
                    >
                      Add
                    </Button>
                  )
                }
              />
              {/* dialog open for insert image */}
              <div>
                <Dialog
                  open={openDialogForImage}
                  disableEnforceFocus
                  onClose={() => setOpenDialogForImage(false)}
                >
                  {/* <DialogTitle>Payment Form</DialogTitle> */}
                  <DialogContent>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div>
                        <TextField
                          onChange={handleFileChange}
                          error={selectedFileError}
                          helperText={selectedFileHelperText}
                          // value={selectedFile}
                          id="outlined-basic"
                          type="file"
                          size="small"
                          InputLabelProps={{
                            style: {
                              fontFamily: "Roboto",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </div>
                      <div className="mt-2">
                        <Reusable_Input
                          type={"text"}
                          label={"Desktop Link"}
                          fullWidth={true}
                          value={url}
                          error={linkError}
                          helperText={linkHelperText}
                          onChange={handleUrlChange}
                        />
                      </div>
                      {/* <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Mobile Link"} fullWidth={true} value={mobLink} error={mobError} helperText={mobHelperText} onChange={handleMobChange} />
                      </div> */}
                      <div className="mt-2">
                        <Reusable_Input
                          type={"text"}
                          label={"Game Name"}
                          fullWidth={true}
                          value={gameName}
                          error={gameNameError}
                          helperText={gameNameHelperText}
                          onChange={handleGameNameChange}
                        />
                      </div>
                      <FormControl
                        className="form-banner mt-2"
                        sx={{ m: 1, width: 200 }}
                        size="small"
                      >
                        <InputLabel
                          className="label-banner"
                          id="demo-select-small-label"
                        >
                          Mobile Page
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={mobileRoute}
                          label="Select"
                          fullWidth
                          onChange={handleDropdownChangeMobilePage}
                          error={typeError}
                        >
                          {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="GameDrawer">Game Page</MenuItem>
                          <MenuItem value="TopGames">Top Games</MenuItem>
                          <MenuItem value="GameList">WACS Casino Page</MenuItem>
                          <MenuItem value="QTechGameList">
                            QTech Casino Page
                          </MenuItem>
                          <MenuItem value="Profile">Profile Page</MenuItem>
                          <MenuItem value="WalletBonusScreen">Bonus</MenuItem>
                          <MenuItem value="DepositScreen">Deposit</MenuItem>
                          <MenuItem value="bonus-welcome-tnc-mobile">
                            Bonus Welcome Banner
                          </MenuItem>
                          <MenuItem value="bonus-refill-tnc-mobile">
                            Bonus Refill Banner
                          </MenuItem>
                          <MenuItem value="bonus-referral-tnc-mobile">
                            Bonus Referral Banner
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        className="form-banner mt-2"
                        sx={{ m: 1, width: 200 }}
                        size="small"
                      >
                        <InputLabel
                          className="label-banner"
                          id="demo-select-small-label"
                        >
                          Mobile Page Options
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={mobileParams}
                          label="Select"
                          fullWidth
                          onChange={handleDropdownChangeMobileParams}
                          error={typeError}
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="Nandi">Satta Matka</MenuItem>
                          <MenuItem value="Falcon">Falcon</MenuItem>
                          <MenuItem value="Jetfair">Jetfair</MenuItem>
                          <MenuItem value="LIVECASINO">Live Casino</MenuItem>
                          <MenuItem value="SLOT">Slots</MenuItem>
                        </Select>
                      </FormControl>
                      <div className="d-flex justify-content-between gp">
                        <FormControl
                          className="form-banner mt-2"
                          sx={{ m: 1, width: 200 }}
                          size="small"
                        >
                          <InputLabel
                            className="label-banner"
                            id="demo-select-small-label"
                          >
                            Select Type
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedOption}
                            label="Select"
                            fullWidth
                            onChange={handleDropdownChange}
                            error={typeError}
                          >
                            {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                            <MenuItem value="banner">Banner</MenuItem>
                            <MenuItem value="game">Game</MenuItem>
                            <MenuItem value="exchangeBanner">Exchange Banner</MenuItem>
                            {/* <MenuItem value="bonusScreenBanners">
                              Bonus Banners
                            </MenuItem> */}
                          </Select>
                        </FormControl>
                        <FormControl
                          className="form-banner mt-2"
                          sx={{ m: 1, width: 200, marginLeft: 5 }}
                          size="small"
                        >
                          <InputLabel
                            className="label-banner"
                            id="demo-select-small-label"
                          >
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedCategory}
                            label="Select"
                            fullWidth
                            onChange={handleCategoryChange}
                            error={categoryError}
                          >
                            <MenuItem value="casino">Casino</MenuItem>
                            <MenuItem value="sattamatka">Satta Matka</MenuItem>
                            <MenuItem value="exchange">Exchange</MenuItem>
                            <MenuItem value="others">Others</MenuItem>
                          </Select>
                        </FormControl>

                        {/* <FormGroup className="mt-2">
                          <FormControlLabel
                          control={
                            <Switch
                            checked={switchAddState}
                            onChange={(e) => handleAddSwitchChange(e)}
                            color="primary"
                            />
                          }
                          label={switchAddState ? "Active" : "Inactive"}
                          />
                        </FormGroup> */}
                      </div>
                      {/* {selectedOption === "bonusScreenBanners" ? (
                        <div>
                          <div className="mt-2">
                            <Reusable_Input
                              type={"text"}
                              label={"Banner Title"}
                              fullWidth={true}
                              value={description?.title ?? ""}
                              error={!!descriptionError?.titleError?.length}
                              helperText={descriptionError?.titleError}
                              onChange={handleBannerTitleChange}
                            />
                          </div>
                          <div className="mt-2">
                            <Reusable_Input
                              type={"text"}
                              label={"Banner Subtitle"}
                              fullWidth={true}
                              value={description?.subTitle ?? ""}
                              error={!!descriptionError?.subtitleError}
                              helperText={descriptionError?.subtitleError}
                              onChange={handleBannerSubTitleChange}
                            />
                          </div>
                        </div>
                      ) : null} */}

                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setOpenDialogForImage(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                          //onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={deletePopup}
                  onClose={() => setDeletePopup(false)}
                >
                  <DialogTitle>Are You Sure ?</DialogTitle>
                  <DialogContent>
                    <DialogActions className="mt-n3">
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        onClick={() => setDeletePopup(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={() => deleteImage()}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Image</th>
                            <th>Game Name</th>
                            {User.data.Responsibilities.includes(
                              Responsibilities.Toggle_Banner
                            ) && <th>Status</th>}
                            <th>Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {images.map((item: Images, index: number) => {
                            return (
                              <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    style={{ width: "200px", height: "75px" }}
                                    src={item.imagePath}
                                  />
                                </td>
                                <td>{item.gameName}</td>
                                {User.data.Responsibilities.includes(
                                  Responsibilities.Toggle_Banner
                                ) && (
                                  <td>
                                    <Switch
                                      id={item._id}
                                      {...label}
                                      checked={item.status}
                                      onChange={(e) => handleSwitchChange(e)}
                                      color="primary"
                                    />
                                  </td>
                                )}
                                <td>{item.type}</td>
                                <td>
                                  {User.data.Responsibilities.includes(
                                    Responsibilities.Delete_Banner
                                  ) && (
                                    <span>
                                      <FontAwesomeIcon
                                        id={item._id}
                                        className="fa fa-pencil-square icon-home icon-trash "
                                        icon={faTrashCan}
                                        onClick={() => openPopup(item._id)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © 2024 kuberbets.vip
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default Banners;
