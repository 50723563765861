import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import { formatDate } from '../../utils/utility'
import Stateful_Select from '../Dropdown/Dropdown'
import "./FundRequest.css"


interface Withdrawal {
    accountHolderName: string;
    amount: number;
    commissionAmount: string;
    transactionId: string;
    dp_id: string;
    accountNo: string;
    bankName: string;
    ifscCode: string;
    mobile: string;
    createdOn: string;
    userName: string;
    userMobile: string;
    paymentGatewayName: string;
    reason: string;
    orderId: string;
    status: string;
    mid: string;
    _id: string;
    withdrewalProviderName: string;
    crossCheckBy: {
        name: string
    }
    checkBy: {
        name: string
    }
    action: {
        name: string
        status: boolean
    }
}

function FundRequestCoinTable() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [fundReqData, setFundReqData] = useState<Withdrawal[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");

    const formatedDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };
    const formattedDate = (timestamp: any) => formatedDate(new Date(timestamp));

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    useEffect(() => {
        let filters: { status?: string, "updatedBy._id"?: string } = {};

        if (customProps.status) {
            filters.status = customProps.status;
        }

        filters["updatedBy._id"] = User.data._id

        let data = customProps
        data.pageNo = currentPage
        data.itemsPerPage = parseInt(itemsPerPage)
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/transaction/getAllTransaction`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setFundReqData(response.data.data.payload.items)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }, [currentPage, itemsPerPage])
    return (
        <>
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={`User ${customProps.type} data`} />
                        <div className="container-fluid">
                            <div className="row">
                            <div className="row tp-form mb-2">
                                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                    <label className="lbl">Items Per Page</label>
                                    <div className="mt-1">
                                        <Stateful_Select
                                            value={itemsPerPage}
                                            onChange={(newValue: any) => handlePerPage(newValue)}
                                            options={["10", "25", "50", "75", "100"]}
                                            className="deposit-select"
                                        />
                                    </div>
                                </div>
                            </div>
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                {customProps.type === "deposit" || customProps.type === "pending"  ? <tr>
                                                    <th></th>
                                                    <th>User Name</th>
                                                    <th>Mobile No</th>
                                                    <th>Transaction Id</th>
                                                    <th>Amount</th>
                                                    <th>Payment Method</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Status</th>
                                                    {customProps.type === "deposit" && customProps.filter.status === "Pending" ? "" : <th>Rejected Reason</th>}

                                                </tr> : <tr>
                                                    <th></th>
                                                    <th>User Name</th>
                                                    <th>Amount</th>
                                                    <th>Commission Amount</th>
                                                    <th>Transaction Id</th>
                                                    <th>Dp Id</th>
                                                    <th>Account No</th>
                                                    <th>Bank Name</th>
                                                    <th>IFSC</th>
                                                    <th>Mobile NO</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Status</th>
                                                    <th>Check By</th>
                                                    <th>Cross Check By</th>
                                                    <th>Withdrawal Provider</th>
                                                    <th>Updated By</th>
                                                </tr>}
                                            </thead>
                                            <tbody>
                                                {customProps.type === "deposit" || customProps.type === "pending" ? (fundReqData?.map((item,index) => (
                                                    <tr key={item._id} className={customProps.filter.status === "Approved" ? "withdraw-approved" : customProps.filter.status === "Pending" ? "withdraw-pending" : customProps.filter.status === "Rejected" ? "withdraw-rejected" : ""} id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td onClick={() => {
                                                            const url = `/user-report/${item._id}/${item.userName}`;
                                                            window.open(url, "_blank");
                                                        }} style={{ cursor: 'pointer' }}>{item?.userName}</td>
                                                        <td>{item.userMobile}</td>
                                                        <td>{item.orderId}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{`${item.paymentGatewayName} - ${item.mid}`}</td>
                                                        <td>{formattedDate(item.createdOn)}</td>
                                                        <td>{formatedTime(item.createdOn)}</td>
                                                        <td>{item.status}</td>
                                                        {customProps.type === "deposit" && customProps.filter.status === "Pending" ? "" : <td>{item.reason}</td>}

                                                    </tr>
                                                ))) : (fundReqData?.map((item,index) => (
                                                    <tr key={item._id} className={customProps.filter.status === "Approved" ? "withdraw-approved" : customProps.filter.status === "Pending" ? "withdraw-pending" : customProps.filter.status === "on hold" ? "withdraw-onhold" : customProps.filter.status === "Reverse" ? "withdraw-reverse" : (customProps.filter.status === "Rejected" || customProps.filter.status === "Cancel") ? "withdraw-rejected" : ""} id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td onClick={() => {
                                                            const url = `/user-report/${item._id}/${item.accountHolderName}`;
                                                            window.open(url, "_blank");
                                                        }} style={{ cursor: 'pointer' }}>{item?.accountHolderName}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{item.commissionAmount}</td>
                                                        <td>{item.transactionId}</td>
                                                        <td>{item.dp_id}</td>
                                                        <td>{item.accountNo}</td>
                                                        <td>{item.bankName}</td>
                                                        <td>{item.ifscCode}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{formatDate(item.createdOn)}</td>
                                                        <td>{formatedTime(item.createdOn)}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.checkBy?.name}</td>
                                                        <td>{item.crossCheckBy?.name}</td>
                                                        <td>{`${item.withdrewalProviderName} - ${item.mid}`}</td>
                                                        <td>{item.action ? `${item.action?.status} by ${item.action?.name}` : ""}</td>
                                                    </tr>
                                                )))}

                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={currentPage}
                                                onChange={(Event, New_Page) =>
                                                    setCurrentPage(New_Page)
                                                }
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default FundRequestCoinTable