import Hero_Image from '../../../../Assets/Images/Hero_Image.webp';
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import "../../../../Css/welcome.css";

const WelcomePage = () => 
<>
	<Sidenav />
	<div className="bg-img position-absolute top-3.5 h-100 d-md-block">
		<img className="position-absolute fixed-top ms-auto z-index-0 Hero_Image" src={Hero_Image}></img>
		<div className="container text-welcome">
			<div className="line text-first">WELCOME TO</div>
			<div className="line text-fair">KUBER BETS VIP</div>
			<div className="admin-pn d-flex justify-content-center mt-2"><span className="line">ADMIN PANEL</span></div>
		</div>
	</div>
</>

export default WelcomePage;