import React from 'react';


function WorliHistory({ data, title }: WorliHistoryProps) {
    return (
        <div className="row mt-4">
            {title && <h2>{title}</h2>}
            <div className="table-responsive">
                <table className="table table-view">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Transaction ID</th>
                            <th>Round ID</th>
                            <th>Game Name</th>
                            <th>Game</th>
                            <th>Point</th>
                            <th>Winning Point</th>
                            <th>Commission</th>
                            <th>Game Date</th>
                            <th>Status</th>
                            <th>Updated On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data.pendingWithdrawals.map(Pending_Withdrawal =>
                                                <tr key={Pending_Withdrawal._id}>
                                                    <td>{Pending_Withdrawal._id}</td>
                                                    <td>{Pending_Withdrawal.dp_id}</td>
                                                    <td>{Pending_Withdrawal.amount}</td>
                                                    <td>{Pending_Withdrawal.ifscCode}</td>
                                                    <td>{Pending_Withdrawal.mobile}</td>
                                                    <td>{Pending_Withdrawal.accountNo}</td>
                                                    <td>{Pending_Withdrawal.accountHolderName}</td>
                                                    <td>{Pending_Withdrawal.transactionId}</td>
                                                    <td>{Pending_Withdrawal.status}</td>
                                                    <td>{formatDate(Pending_Withdrawal.createdOn)}</td>
                                                    <td>{formatDate(Pending_Withdrawal.updatedOn)}</td>
                                                </tr>)} */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WorliHistory;

interface WorliHistoryProps {
    data?: any,
    title?: String
}