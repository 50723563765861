import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { formatDate } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import Stateful_Select from '../Dropdown/Dropdown'



interface Pldata {
    _id: string;
    name: string;
    balance: number;
    createdOn: string;
    email: string;
    mobile: string;
    updatedOn: string;
    state: string;
    city: string

}



function NonPerformingUser() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [performingUser, setNonPerformingUser] = useState<Pldata[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [totalUser, setTotalUser] = useState<number>(0)

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    useEffect(() => {
        getData()
    }, [currentPage, itemsPerPage]);

    const getData = () => {

        let data;
        if (startDate && endDate) {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                startDate,
                endDate
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                // filters: filters
            };
        }


        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/nonPerformingUser`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setNonPerformingUser(response.data.data.payload.items)
                setTotalUser(response.data.data.payload.total)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }

    const clearDates = () => {
        setStartDate('')
        setEndDate('')
    }

    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 7);

    return (
        <div className="g-sidenav-show  bg-gray-100">
            <Sidenav />
            <main className="main-content position-relative">
                <div style={{ background: "#f8f9fa" }}>
                    <Breadcrumbs tab={"User Data"} />
                    <div className="container-fluid">
                    <div className="row">
                        <div className="row tp-form mb-1" style={{ display: 'flex' }}>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="From Date"
                                    value={startDate}
                                    max={oneWeekBefore.toISOString().split('T')[0]}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl">To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="To Date"
                                    value={endDate}
                                    max={oneWeekBefore.toISOString().split('T')[0]}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl"></label>
                                <a onClick={getData} className="sechBtn mt-1">
                                    Apply
                                </a>
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl"></label>
                                <a onClick={clearDates} className="sechBtn mt-1">
                                    clear dates
                                </a>
                            </div>

                            <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                                <label className="lbl">Items Per Page</label>
                                <Stateful_Select
                                    // label="Items Per Page"
                                    value={itemsPerPage.toString()}
                                    onChange={(newValue: any) => handlePerPage(newValue)}
                                    options={["10", "25", "50", "75", "100"]}
                                />
                            </div>
                        </div>
                        <div className="align-items-center mt-2 mb-2">
                        <label className="lbl"></label>
                        <b>Total user count : {totalUser}</b>
                    </div>
                        
                    </div>
    
                    <div className="row">
                            <div className="row tp-form">
                                {/* <div className="col-6 col-xl-2 col-sm-4 pdrt mb-2">
                                    <Stateful_Select
                                        label="Items Per Page"
                                        value={itemsPerPage.toString()}
                                        onChange={(newValue: any) => handlePerPage(newValue)}
                                        options={["10", "25", "50", "75", "100"]}
                                    />
                                </div> */}
                            </div>
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>Balance</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Created</th>
                                                <th>last Activity</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {performingUser?.map((item, index) => (
                                                <tr id={item._id}>
                                                    <td
                                                    onClick={() => {
                                                        const url = `/user-report/${item._id}/${item.name}`;
                                                        window.open(url, "_blank");
                                                      }} style={{ cursor: 'pointer' }}
                                                    >{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.mobile}</td>
                                                    <td>{Math.round(item.balance)}</td>
                                                    <td>{item.state}</td>
                                                    <td>{item.city}</td>
                                                    {/* <td>{item.utr}</td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.remark}</td>
                                                    <td>{item.tag === "credit" ? "Deposit" : "Debit"}</td>
                                                    <td>{item.updatedBy.name}</td> */}
                                                    <td>{item.createdOn ? `${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}` : ""}</td>
                                                    <td>{item.updatedOn ? `${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}` : ""}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination d-flex justify-content-center">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPages}
                                            color="secondary"
                                            page={currentPage}
                                            onChange={(Event, New_Page) =>
                                                setCurrentPage(New_Page)
                                            }
                                        />
                                    </Stack>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NonPerformingUser