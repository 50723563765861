import React, {
  FormEvent,
  ChangeEvent,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../Casino_Games/Casino_Games.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../Components/Loader/Loader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";

interface CasinoGame {
  _id: string;
  game_name: string;
  Url: string;
  Description: string;
  Image: string;
  MobileUrl: string;
  Branded: string;
  SuperBranded: string;
  hasDemo: string;
  CategoryID: string;
  SortPerCategory: string;
  merchantId: string;
  SubMerchantID: string;
  AR: string;
  IDCountryRestriction: string;
  ImageFullPath: string;
  Sort: string;
  MobilePageCode: string;
  MobileAndroidPageCode: string;
  IsVirtual: string;
  tableId: string;
  CustomSort: string;
  RTP: string;
  status: boolean;
}

const GameCategoryData = [
  "Andar Bahar",
  "Roulette",
  "Dragon Tiger",
  "Lucky Sevens",
  "Poker",
  "Teen Patti",
  "BlackJack",
];

function CasinoList() {
  const [addCasinoPopup, setAddCasinoPopup] = useState(false);
  const [gameName, setGameName] = useState<string>("");
  const [merchantId, setMerchantId] = useState<string>("");
  const [_id, set_id] = useState<string>("");
  const [urlErrorMessage, setUrlMessage] = useState<string>("");
  const [imageErrorMessage, setImageErrorMessage] = useState<string>("");
  const [urlError, setUrlError] = useState<boolean>(false);
  const [allGame, setAllGame] = useState<Array<CasinoGame>>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [gameError, setGameError] = useState<boolean>(false);
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [imageFullPathError, setImageFullPathError] = useState<boolean>(false);
  const [categoryIdError, setCategoryIdError] = useState<boolean>(false);
  const [merchantIdError, setMerchantIdError] = useState<boolean>(false);
  const [tableIdError, setTableIdError] = useState<boolean>(false);
  const [merchantNameError, setMerchantNameError] = useState<boolean>(false);
  const [gameNameErrorMessage, setGameNameErrorMessage] = useState<string>("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState<string>("");
  const [categoryIdErrorMessage, setCategoryIdErrorMessage] =
    useState<string>("");
  const [merchantIdErrorMessage, setMerchantIdErrorMessage] =
    useState<string>("");
  const [imageFullPathErrorMessage, setImageFullPathErrorMessage] =
    useState<string>("");
  const [tableIdErrorMessage, setTableIdErrorMessage] = useState<string>("");
  const [merchantNameErrorMessage, setMerchantNameErrorMessage] =
    useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [imgFullPath, setImgFullPath] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const [tableId, setTableId] = useState<string>("");
  const [merchantName, setMerchantName] = useState<string>("");
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1); // Initialize with 1
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1
  const { User } = useContext(User_Context);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [gameCategoryOptionKey, setGameCategoryOptionKey] =
    useState<string>("");
  const [gameNameSearchKey, setGameNameSearchKey] = useState<string>("");
  const [gameIdSearchKey, setGameIDSearchKey] = useState<string>("");
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [providerNameSearchKey, setProviderNameSearchKey] =
    useState<string>("");
  const [newGameId, setNewGameId] = useState<string>("");

  const providerList = useRef([]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const checked = event.target.checked;
    const id = event.target.id;
    const apiUrl = `${API_Endpoint}/Qtech/Edit-Games`;
    const postData = {
      gameId: id,
      status: checked,
    };
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const updatedGameStatus = allGame.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                status: checked,
              };
            } else return item;
          });
          setAllGame(updatedGameStatus);
          getAllGames();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };
  const openDeletePopup = (id: string) => {
    setDeletePopup(true);
    set_id(id);
  };

  const deleteGame = () => {
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${API_Endpoint}/casinoGames/delete`,
          { token: encryptData({ _id: _id }) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          const updatedGames = allGame.filter((item) => item._id !== _id);
          setAllGame(updatedGames);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const getAllGames = () => {
    setLoading(true);
    const apiUrl = `${API_Endpoint}/casinoGames/getDataWithPages`;
    const postData: {
      pageNo: number;
      itemsPerPage: number;
      Filters: { [key: string]: string };
    } = {
      pageNo: currentPage,
      itemsPerPage: itemsPerPage,
      Filters: {},
    };
    if (gameCategoryOptionKey || gameNameSearchKey) {
      postData.Filters["Name"] = gameCategoryOptionKey || gameNameSearchKey;
    }
    if (gameIdSearchKey) {
      postData.Filters["gameId"] = gameIdSearchKey;
    }
    if (providerNameSearchKey) {
      postData.Filters["provider.name"] = providerNameSearchKey;
    }
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          setAllGame(response.data.data.payload.items);
          setTotalPages(response.data.data.payload.totalPages);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(`${err.message}`);
        });
    }
  };

  const getProviderList = () => {
    const apiUrl = `${API_Endpoint}/Qtech/Get-provider`;
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData({}) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          providerList.current = response?.data?.data?.payload || [];
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddNewGameSubmit = () => {
    if (!gameName) {
      setGameError(true);
      setGameNameErrorMessage("enter game name");
      return;
    } else if (!newGameId) {
      setCategoryError(true);
      setCategoryErrorMessage("please enter category");
      return;
    }
    setLoading(true);
    const apiUrl = `${API_Endpoint}/Qtech/Add-Games`;
    const token = localStorage.getItem("token");
    const postData = {
      gameId: newGameId,
      Name: gameName,
    };
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          getAllGames();
          setAddCasinoPopup(false);
          setLoading(false);
          return;
        })
        .catch((error) => {
          console.log(error);
          setAddCasinoPopup(false);
          setLoading(false);
          return;
        });
    }
  };

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue);
  };

  useEffect(() => {
    getProviderList();
  }, []);

  useEffect(() => {
    getAllGames();
  }, [currentPage, itemsPerPage]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // if (!selectedFile) {
    //   setSelectedFileError(true);
    //   setSelectedFileErrorMessage("Choose file");
    // }
    if (!gameName) {
      setGameError(true);
      setGameNameErrorMessage("enter game name");
    } else if (!category) {
      setCategoryError(true);
      setCategoryErrorMessage("please enter category");
    } else if (!url) {
      setUrlError(true);
      setUrlMessage("please enter url");
    } else if (!imageUrl) {
      setImageError(true);
      setImageErrorMessage("please enter image url");
    } else if (!categoryId) {
      setCategoryIdError(true);
      setCategoryIdErrorMessage("please enter category id message");
    } else if (!merchantId) {
      setMerchantIdError(true);
      setMerchantIdErrorMessage("please select merchant id");
    } else if (!imgFullPath) {
      setImageFullPathError(true);
      setImageFullPathErrorMessage("Please add image full path");
    } else if (!tableId) {
      setTableIdError(true);
      setTableIdErrorMessage("please enter table id ");
    } else if (!merchantName) {
      setMerchantNameError(true);
      setMerchantNameErrorMessage("please add merchant name ");
    } else {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token) {
        const data = {
          Name: gameName,
          ImageFullPath: imgFullPath,
          Category: category,
          CategoryID: categoryId,
          MerchantID: merchantId,
          TableID: tableId,
          Url: url,
          Image: imageUrl,
          merchantName: merchantName,
          status: status,
        };
        const config = {
          method: "post",
          url: `${API_Endpoint}/casinoGames`,
          data: { token: encryptData(data) },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            response.data.data = decryptData(response.data.data);
            setAllGame(response.data.data.payload);
            setAddCasinoPopup(false);
            window.location.reload();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            console.log(`${err.message}`);
          });
      }
    }
  };

  const handleOnSubmitButtonPress = () => {
    if (!isFilterApplied) {
      getAllGames();
      setIsFilterApplied(true);
      return;
    }
    setIsFilterApplied(false);
    setGameCategoryOptionKey("");
    setGameNameSearchKey("");
    setGameIDSearchKey("");
    setProviderNameSearchKey("");
  };

  const GameCategoryDropdown = ({
    title = "Game Category",
    type = "Default",
  }: {
    title?: string;
    type?: "Default" | "New";
  }) => (
    <div
      className={
        type === "Default"
          ? "col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web"
          : ""
      }
    >
      <label className="lbl">{title}</label>
      <Stateful_Select
        value={type === "Default" ? gameCategoryOptionKey : gameName}
        onChange={(newValue) => {
          type === "Default"
            ? setGameCategoryOptionKey(newValue)
            : setGameName(newValue);
        }}
        options={GameCategoryData}
      />
    </div>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs
                tab={"Casino Games"}
                button={
                  User.data.Responsibilities.includes(
                    Responsibilities.Add_Game
                  ) && (
                    <Button
                      onClick={() => setAddCasinoPopup(true)}
                      className="btn-payment"
                      variant="contained"
                    >
                      Add
                    </Button>
                  )
                }
              />
              <div>
                <Dialog
                  open={addCasinoPopup}
                  onClose={() => setAddCasinoPopup(false)}
                >
                  <DialogContent>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div>
                        <Reusable_Input
                          label={"Game ID"}
                          value={newGameId}
                          error={gameError}
                          helperText={gameNameErrorMessage}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setGameError(false);
                            setNewGameId(event.target.value);
                          }}
                          type={"text"}
                          fullWidth={true}
                        />
                      </div>
                      <div className="mt-2">
                        {/* <Reusable_Input
                                                    label={"Category"}
                                                    value={category}
                                                    error={categoryError}
                                                    helperText={
                                                        categoryErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setCategoryError(false);
                                                        setCategory(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                /> */}
                        <GameCategoryDropdown
                          title={"Game Name"}
                          type={"New"}
                        />
                      </div>
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Url"}
                                                    value={url}
                                                    error={urlError}
                                                    helperText={urlErrorMessage}
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setUrlError(false);
                                                        setUrl(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Image URL"}
                                                    value={imageUrl}
                                                    error={imageError}
                                                    helperText={
                                                        imageErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setImageError(false);
                                                        setImageUrl(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    className="mt-2"
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setImageFullPathError(
                                                            false
                                                        );
                                                        setImgFullPath(
                                                            event.target.value
                                                        );
                                                    }}
                                                    // error={linkError}
                                                    // helperText={linkHelperText}
                                                    value={imgFullPath}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    label="Image Full Path"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily:
                                                                "Roboto",
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Category Id"}
                                                    value={categoryId}
                                                    error={categoryIdError}
                                                    helperText={
                                                        categoryIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setCategoryIdError(
                                                            false
                                                        );
                                                        setCategoryId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Merchant Id"}
                                                    value={merchantId}
                                                    error={merchantIdError}
                                                    helperText={
                                                        merchantIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setMerchantIdError(
                                                            false
                                                        );
                                                        setMerchantId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Table Id"}
                                                    value={tableId}
                                                    error={tableIdError}
                                                    helperText={
                                                        tableIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setTableIdError(false);
                                                        setTableId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}

                      {/* <div>
                                                <TextField
                                                    className="mt-2"
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setMerchantName(
                                                            event.target.value
                                                        );
                                                    }}
                                                    // error={linkError}
                                                    // helperText={linkHelperText}
                                                    value={merchantName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    label="Merchant Name"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily:
                                                                "Roboto",
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            </div> */}
                      {/* <div>
                                                <FormGroup className="mt-2">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={status}
                                                                onChange={(e) =>
                                                                    setStatus(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label={
                                                            status
                                                                ? "Active"
                                                                : "Inactive"
                                                        }
                                                    />
                                                </FormGroup>
                                            </div> */}

                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setAddCasinoPopup(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                          onClick={handleAddNewGameSubmit}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={deletePopup}
                  onClose={() => setDeletePopup(false)}
                >
                  <DialogTitle>Are You Sure ?</DialogTitle>
                  <DialogContent>
                    <DialogActions className="mt-n3">
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        onClick={() => setDeletePopup(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={() => {
                          deleteGame();
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                      <label className="lbl">Items Per Page</label>
                      <Stateful_Select
                        value={itemsPerPage.toString()}
                        onChange={(newValue: any) => handlePerPage(newValue)}
                        options={[
                          "10",
                          "25",
                          "50",
                          "75",
                          "100",
                          "120",
                          "150",
                          "200",
                        ]}
                      />
                    </div>
                    <GameCategoryDropdown />
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <Reusable_Input
                        label={"Game Name"}
                        value={gameNameSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setGameNameSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        isDisabled={!!gameCategoryOptionKey?.length}
                        customInputTextStyles={{
                          padding: "6px",
                        }}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <Reusable_Input
                        label={"Game ID"}
                        value={gameIdSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setGameIDSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        customInputTextStyles={{
                          padding: "6px",
                        }}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <label className="lbl">Provder Name</label>
                      <Stateful_Select
                        value={providerNameSearchKey}
                        onChange={(newValue) => {
                          setProviderNameSearchKey(newValue);
                        }}
                        options={providerList.current}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <Button
                        onClick={handleOnSubmitButtonPress}
                        className="sech-button px-2 py-1"
                        variant="contained"
                        disabled={
                          !gameCategoryOptionKey &&
                          !gameNameSearchKey &&
                          !gameIdSearchKey &&
                          !providerNameSearchKey
                        }
                      >
                        {isFilterApplied ? "Clear Data" : "Submit"}
                      </Button>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            {/* id, name, gameId, provider name, provider id, category, */}
                            <th>ID</th>
                            <th>Game Name</th>
                            <th>Game ID</th>
                            <th>Provider ID</th>
                            <th>Category</th>
                            <th>Image</th>
                            {User.data.Responsibilities.includes(
                              Responsibilities.Toggle_Game
                            ) && <th>Status</th>}
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {allGame.map((item: any, index: number) => {
                            return (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item._id}</td>
                                  <td>{item.Name}</td>
                                  <td>{item.gameId}</td>
                                  <td>{item.provider.id}</td>
                                  <td>{item.category}</td>
                                  <td>
                                    <img
                                      className="image-casino"
                                      src={item?.images?.[1]?.url ?? ""}
                                    ></img>
                                  </td>
                                  {User.data.Responsibilities.includes(
                                    Responsibilities.Toggle_Game
                                  ) && (
                                    <td>
                                      <Switch
                                        id={item.gameId}
                                        {...label}
                                        checked={item.status}
                                        color="primary"
                                        //onChange={(e) => setActive(e.target.checked)}
                                        onChange={(e) => handleSwitchChange(e)}
                                      />
                                    </td>
                                  )}
                                  {/* <td>
                                    {User.data.Responsibilities.includes(Responsibilities.Delete_Game) && <span>
                                      <FontAwesomeIcon
                                        //id
                                        className="fa fa-pencil-square icon-home icon-trash "
                                        icon={faTrashCan}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          openDeletePopup(item._id)
                                        }
                                      />
                                    </span>}
                                  </td> */}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © 2024 kuberbets.vip
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default CasinoList;
