import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./KYC.css"
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Button from "@mui/material/Button";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import * as lodash from "lodash"
import { Pagination, Stack, TextField } from "@mui/material";
import Loader from "../../../../Components/Loader/Loader";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import { dateTime } from "../../../../utils/utility";
import { ToastContainer, toast } from "react-toastify";


function Userkyc() {
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [Files, Set_Files] = useState<string[]>([]);
  const [File_Extensions, Set_File_Extensions] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [IFSCs, Set_IFSCs] = useState<string[]>([]);
  const [KYCs, Set_KYCs] = useState<boolean[]>([]);
  const [Bank_Accounts, Set_Bank_Accounts] = useState<string[]>([]);
  const { User } = useContext(User_Context);
  const Navigate = useNavigate();
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchMob, setSearchMob] = useState<string>("");
  const [searchUserId, setSearchUserId] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");


  const Change_IFSC = (IFSC: string, Index: number): void => Set_IFSCs(IFSCs.map((IFSC_Value: string, IFSC_Index: number) => IFSC_Index === Index ? IFSC : IFSC_Value));
  const Change_Bank_Account = (Bank_Account: string, Index: number): void => Set_Bank_Accounts(Bank_Accounts.map((Bank_Account_Value: string, Bank_Account_Index: number) => Bank_Account_Index === Index ? Bank_Account : Bank_Account_Value));
  const Change_File = (Base64_File: string, Index: number): void => Set_Files(Files.map((File_Value: string, File_Index: number) => File_Index === Index ? Base64_File : File_Value));
  const Change_KYC = (KYC: boolean, Index: number): void => Set_KYCs(KYCs.map((KYC_Value: boolean, KYC_Index: number) => KYC_Index === Index ? KYC : KYC_Value));


  const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserId(event.target.value);
  };
  const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(event.target.value);
  };
  const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchMob(event.target.value);
  };

  const filterTransaction = (e: FormEvent) => {
    e.preventDefault()
    if (!startDate) {
      toast("Please select from date")
    } else if (!endDate) {
      toast("Please select to date")
    } else {
      setLoading(true);
      let data = {
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filter: {},
        startDate: dateTime(startDate),
        endDate: dateTime(endDate)
      };
      const token = localStorage.getItem("token");
      if (token) {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${API_Endpoint}/User/getAll`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { token: encryptData(data) },
        };

        axios
          .request(config)
          .then((response) => {
            response.data.data = decryptData(response.data.data);
            response.data.data = response.data.data.payload;
            if (response.data.data.items.length <= 0) {
              toast("No kyc registered for selected date")
            }
            setData(response.data.data.items)
            setTotalPages(response.data.data.totalPages);
            Set_Bank_Accounts(response.data.data.items.map((Item: any) => Item.accountNumber));
            Set_IFSCs(response.data.data.items.map((Item: any) => Item.ifsc));
            Set_Files(response.data.data.items.map((Item: any) => Item.aadhaarImageBase64));
            Set_KYCs(response.data.data.items.map((Item: any) => Item.kyc));
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false)
            console.log(error);
          });
      }
    }

  }
  const filterUser = () => {
    setLoading(true);
    const filter: { _id?: string, mobile?: string, name?: string } = {};
    if (searchUserId) {
      filter._id = searchUserId
    }
    if (searchUserName) {
      filter.name = searchUserName
    }
    if (searchMob) {
      filter.mobile = searchMob
    }
    let data = {
      itemsPerPage: 10,
      pageNo: currentPage,
      filter: filter
    };
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/User/getAll`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { token: encryptData(data) },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          setData(response.data.data.items)
          setTotalPages(response.data.data.totalPages);
          Set_Bank_Accounts(response.data.data.items.map((Item: any) => Item.accountNumber));
          Set_IFSCs(response.data.data.items.map((Item: any) => Item.ifsc));
          Set_Files(response.data.data.items.map((Item: any) => Item.aadhaarImageBase64));
          Set_KYCs(response.data.data.items.map((Item: any) => Item.kyc));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
        });
    }
  }
  useEffect(() => {
    setLoading(true);
    let data = {
      itemsPerPage: itemsPerPage,
      pageNo: currentPage,
      filter: {}
    };
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/User/getAll`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { token: encryptData(data) },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          if (response.data.data.items.length <= 0) {
            toast("No kyc registered for todays date")
          }
          setData(response.data.data.items)
          setTotalPages(response.data.data.totalPages);
          Set_Bank_Accounts(response.data.data.items.map((Item: any) => Item.accountNumber));
          Set_IFSCs(response.data.data.items.map((Item: any) => Item.ifsc));
          Set_Files(response.data.data.items.map((Item: any) => Item.aadhaarImageBase64));
          Set_KYCs(response.data.data.items.map((Item: any) => Item.kyc));
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false)
          console.log(error);
        });
    }
  }, [currentPage, itemsPerPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }


  const handleImageClick = (image: string | null): void => {
    setEnlargedImage(image);
  };


  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const formatDate = (dateString: string) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined)
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, Index: number) => {
    if (event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const Base64_Image = reader.result?.toString();
        Set_File_Extensions([...File_Extensions.slice(0, Index), Base64_Image?.includes('jpg') ? 'jpg' : (Base64_Image?.includes('jpeg') ? 'jpeg' : (Base64_Image?.includes('png') ? 'png' : '')), ...File_Extensions.slice(Index + 1)]);
        Change_File(Base64_Image as string, Index);
      };
    }
  };

  const rejectKYC = (id: string, Index: number) => {
    setLoading(true)
    const kyc = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${API_Endpoint}/kyc/reject`;
        const payload = { token: encryptData({ _id: id }) };
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          Change_KYC(false, Index);
          setLoading(false)
          toast("KYC Rejected Successfully")
        }
      } catch (error) {
        setLoading(false)
        console.error("Error in KYC rejection", error);
      }
    };
    kyc();
  };

  const approveKYC = (_id: string, accountNumber: string, ifsc: string, image: string, File_Extension: string, Index: number) => {
    setLoading(true)
    const kyc =async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${API_Endpoint}/kyc/approve`;
        const payload = { token: encryptData({ _id, accountNumber, ifsc, Aadhaar_Card_Image: image, File_Extension: File_Extension }) };
        console.log({ _id, accountNumber, ifsc, Aadhaar_Card_Image: image, File_Extension: File_Extension });
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {  
          toast("KYC Approved Successfully")
          Change_KYC(true, Index);
          setLoading(false)
        } else {
          toast("KYC failed");
          setLoading(false)
        }
      } catch (error) {
        toast("KYC failed");
        setLoading(false)
        console.error("Error in KYC approval", error);
      }
    };
    kyc();
  };




  return (
    <>
      {loading ? (<Loader />) : <div className="g-sidenav-show  bg-gray-100">
        <ToastContainer autoClose={2000} position="top-center" />
        <Sidenav />
        <main className="main-content position-relative">
          <div style={{ background: "#f8f9fa" }}>
            <Breadcrumbs tab={"KYC"} />

            {enlargedImage && (
              <div className="enlarged-image-overlay">
                <div className="enlarged-image-container">
                  <img src={enlargedImage} alt="Enlarged" />
                </div>
                <button
                  className="close-enlarged-image"
                  onClick={closeEnlargedImage}
                >
                  Close
                </button>
              </div>
            )}

            <div className="container-fluid">
              <div className="row">
                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                  <label className="lbl">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                  <label className="lbl">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                  <label className="lbl">Items Per Page</label>
                  <div className="mt-1">
                    <Stateful_Select
                      // label="Select User Type"
                      value={itemsPerPage.toString()}
                      onChange={(newValue: any) => handlePerPage(newValue)}
                      options={["10", "25", "50", "75", "100"]}
                    />
                  </div>
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                  <label className="lbl"></label>
                  <a onClick={filterTransaction} className="sechBtn mt-1">
                    Apply
                  </a>
                </div>
                <div className="col-12 mt-2">
                  <div className="table-responsive">
                    <table className="table table-view">
                      <thead>
                        <tr>
                          <th></th>
                          <th>User ID</th>
                          <th>User Name</th>
                          <th>Mobile Number</th>
                          <th>IFSC</th>
                          <th>Bank Account</th>
                          <th>Aadhar No</th>
                          {User.data.Responsibilities.includes(Responsibilities.Upload_KYC_Cards) && <th>Aadhar card</th>}
                          {User.data.Responsibilities.includes(Responsibilities.Upload_KYC_Cards) && <th>Upload Image</th>}
                          <th>Status</th>
                          <th>Date</th>
                          {(User.data.Responsibilities.includes(Responsibilities.Reject_KYC) || User.data.Responsibilities.includes(Responsibilities.Approve_KYC)) && <th>Action</th>}
                        </tr>
                      </thead>
                      <thead>
                        <tr className="bg-table">
                          <th className="thdr">
                          </th>
                          <th className="thdr">
                            <div className="d-flex justify-content-center">
                              <SearchBar value={searchUserId} onChange={handleSearchUserId} onSearch={filterUser} placeholder="Search by user id" />
                            </div>

                          </th>
                          <th className="thdr">
                            <div className="d-flex justify-content-center">
                              <SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterUser} placeholder="Search by user name" />
                            </div>

                          </th>
                          <th className="thdr">
                            <div className="d-flex justify-content-center">
                              <SearchBar value={searchMob} onChange={handleSearchUserMob} onSearch={filterUser} placeholder="Search by mobile" />
                            </div>

                          </th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                          <th className="thdr"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.map(({ _id: id, name, mobile,aadhaarNumber, ifsc, accountNumber, aadhaarImageBase64: image, kyc, createdOn: date, item }, Index) => (


                            <tr>
                              <td>{Index + 1}</td>
                              <td>{id}</td>
                              <td style={{ cursor: "pointer" }} key={id} onClick={() => {
                                const url = `/user-report/${id}/${name}`;
                                window.open(url, "_blank");
                              }}>{name}</td>
                              <td>{mobile}</td>
                              <td>{<TextField
                                className="mt-2 text-kyc"
                                type="text"
                                fullWidth
                                sx={{ width: { xs: 120, md: 250 } }}
                                variant="outlined"
                                label="IFSC"
                                size="small"
                                value={IFSCs[Index]}
                                onChange={(Event) => Change_IFSC(Event.target.value, Index)}
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                  },
                                }}
                              />}</td>
                              <td>{<TextField
                                className="mt-2 text-kyc"
                                type="text"
                                fullWidth
                                variant="outlined"
                                label="Bank Account"
                                size="small"
                                sx={{ width: { xs: 120, md: 150 } }}
                                value={Bank_Accounts[Index]}
                                onChange={(Event) => Change_Bank_Account(Event.target.value, Index)}
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                  },
                                }}
                              />}</td>
                              <td>{aadhaarNumber}</td>
                              {User.data.Responsibilities.includes(Responsibilities.Upload_KYC_Cards) && (Files[Index] ? <td
                                className="td-card"
                                onClick={() => handleImageClick(Files[Index])}
                              >
                                View Aadhar Card
                              </td> : <td>Aadhar card is not uploaded</td>)}
                              {User.data.Responsibilities.includes(Responsibilities.Upload_KYC_Cards) && <td>
                                <div id={Index.toString()}>
                                  <input
                                    accept="image/png, image/jpeg, image/jpg"
                                    style={{ display: 'none' }}
                                    id={`outlined-none-${Index}`}
                                    onChange={(Event) => handleFileChange(Event, Index)}
                                    type="file"
                                  />
                                  <label htmlFor={`outlined-none-${Index}`} style={{ fontFamily: "Roboto", fontSize: "14px", margin: "0" }}>
                                    <Button variant="contained" component="span">
                                      Upload
                                    </Button>
                                  </label>
                                </div>
                              </td>}
                              <td>{KYCs[Index] ? "Approved" : "Rejected"}</td>
                              <td>{formatDate(date)}</td>
                              <td>
                                {(KYCs[Index] && User.data.Responsibilities.includes(Responsibilities.Reject_KYC)) && <span className="me-1">
                                  <Button
                                    className="withdraw-btn"
                                    variant="contained"
                                    onClick={() => rejectKYC(id, Index)}
                                  >
                                    Reject
                                  </Button>
                                </span>}
                                {(!KYCs[Index] && User.data.Responsibilities.includes(Responsibilities.Approve_KYC)) && <span className="ms-1">
                                  <Button
                                    className="withdraw-btn"
                                    variant="contained"
                                    onClick={() => approveKYC(id, Bank_Accounts[Index], IFSCs[Index], Files[Index], File_Extensions[Index], Index)}
                                  >
                                    Approve
                                  </Button>
                                </span>}
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination d-flex justify-content-center">
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPages}
                        color="secondary"
                        page={currentPage}
                        onChange={(Event, New_Page) =>
                          setCurrentPage(New_Page)
                        }
                      />
                    </Stack>
                  </ul>
                </div>
              </div>

              <footer className="footer">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0  ">
                      <div className="copyright text-center text-sm text-muted text-lg-start">
                        © 2024 kuberbets.vip
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link pe-0 text-muted" target="_blank">
                            
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </main>
      </div>}

    </>
  );
}

export default Userkyc;