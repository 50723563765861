import React, { useState, useContext, FormEvent, ChangeEvent } from 'react'
import Reusable_Input from '../InputField/InputField'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./coins.css"
import {
    DialogActions,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import axios from 'axios';
import { API_Endpoint } from '../../Configuration/Settings';
import { encryptData } from '../../utils/encryptData';
import { decryptData } from '../../utils/decryptData';
import Loader from '../Loader/Loader';
import { User_Context } from '../../Contexts/User';
import { ToastContainer, toast } from "react-toastify";
import { useParams } from 'react-router-dom';

function Coin() {
    const [amount, setAmount] = useState<string>("")
    const [amountError, setAmountError] = useState<boolean>(false)
    const [amountHelperText, setAmountHelperText] = useState<string>("")
    const [remark, setRemark] = useState<string>("")
    const [remarkError, setRemarkError] = useState<boolean>(false)
    const [remarkHelperText, setRemarkHelperText] = useState<string>("")
    const [utr, setUtr] = useState<any>()
    const [utrError, setUtrError] = useState<boolean>(false)
    const [utrHelperText, setUtrHelperText] = useState<string>("")
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [typeError, setTypeError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { User } = useContext(User_Context);
    const { User_ID } = useParams();

    const handleAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setAmountError(false)
        setAmount(e.target.value)
    }
    const handleRemark = (e: ChangeEvent<HTMLInputElement>) => {
        setRemarkError(false)
        setRemark(e.target.value)
    }
    const handleUtr = (e: ChangeEvent<HTMLInputElement>) => {
        setUtrError(false)
        setUtr(e.target.value)
    }
    const handleDropdownChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value);
        setTypeError(false);
    };
    const handleAddCoin = (id: string) => {

        let tag, message: string

        if (id === "add") {
            tag = "credit";
            message = "Coins Added Successfully";
        }
        else if (id === "remove") {
            tag = "debit";
            message = "Coins removed Successfully";
        }
        if (!amount) {
            setAmountError(true)
            setAmountHelperText("Please enter amount")
        } else if (!remark) {
            setRemarkError(true)
            setRemarkHelperText("Please enter remark")
        } else {
            setLoading(true)
            let payload = utr ? {
                token: encryptData({
                    userId: User_ID,
                    balance: amount,
                    updatedBy: {
                        name: User.data.name,
                        _id: User.data._id
                    },
                    reason: selectedOption,
                    remark: remark,
                    tag: tag,
                    utr: utr
                })
            } : {
                token: encryptData({
                    userId: User_ID,
                    balance: amount,
                    updatedBy: {
                        name: User.data.name,
                        _id: User.data._id
                    },
                    reason: selectedOption,
                    remark: remark,
                    tag: tag,
                })
            }

            const token = localStorage.getItem("token");
            axios
                .post(
                    `${API_Endpoint}/coin/add`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    response.data.data = decryptData(response.data.data);
                    setLoading(false)
                    toast(`${message}`);
                    setRemark("")
                    setUtr("")
                    setAmount("")
                    setSelectedOption("")
                })
                .catch((err) => {
                    console.log(err.message);
                    toast(err.message);
                    setLoading(false);
                });
        }
    }


    return (
        <>
            {loading ? (<Loader />) : (<div className='parent-container'>
            <ToastContainer autoClose={2000} position="top-center" />
                <div className='centered-div'>
                    <div className='mt-2 text-inp'>
                        <label>Amount</label>
                        <Reusable_Input label={"Enter Amount"} value={amount} error={amountError} helperText={amountHelperText} onChange={handleAmount} type={"number"} fullWidth={true} />
                    </div>
                    <div className='mt-2'>
                        <label>Reason</label>
                        <div className='mt-n2'>
                            <FormControl
                                className="form-banner mt-2"
                                sx={{ m: 1, width: 300 }}
                                size="small"
                            >
                                <InputLabel
                                    className="label-banner"
                                    id="demo-select-small-label"
                                >
                                    Select
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedOption}
                                    label="Select"
                                    fullWidth
                                    onChange={handleDropdownChange}
                                    error={typeError}
                                >
                                    <MenuItem value="Satta Matka">Satta Matka</MenuItem>
                                    <MenuItem value="Casino">Casino</MenuItem>
                                    <MenuItem value="Exchange">Exchange</MenuItem>
                                    <MenuItem value="Falcon">Falcon</MenuItem>
                                    <MenuItem value="Wac">Wac</MenuItem>
                                    <MenuItem value="Bonus">Bonus</MenuItem>
                                    <MenuItem value="Scanner Deposit">Scanner Deposit</MenuItem>
                                    <MenuItem value="Manual">Manual</MenuItem>
                                    <MenuItem value="Qtech">Qtech</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {selectedOption === "Scanner Deposit" && (
                        <div className='mt-1 text-inp'>
                            <label>UTR</label>
                            <Reusable_Input label={"Enter UTR"} value={utr} error={utrError} helperText={utrHelperText} onChange={handleUtr} type={"text"} fullWidth={true} />
                        </div>
                    )}
                    <div className='mt-1 text-inp'>
                        <label>Remark</label>
                        <Reusable_Input label={"Enter Remark"} value={remark} error={remarkError} helperText={remarkHelperText} onChange={handleRemark} type={"text"} fullWidth={true} />
                    </div>

                    <div className='d-flex justify-content-around mt-4 mb-4'>
                            <div>
                                <Button
                                    // className="btn-popup"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddCoin("add")}
                                >
                                    Add Coins
                                </Button>
                            </div>

                            <div>
                                <Button
                                    // className="btn-popup"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddCoin("remove")}
                                >
                                    Remove Coins
                                </Button>
                            </div>
                    </div>
                </div>
            </div>)}
        </>

    )
}

export default Coin