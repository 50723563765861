import React, { useState, useEffect, useContext } from "react";
import "../../../../Css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import StatCard from "../../../../Components/Statistics/StatCard";
import DashboardCard from "../../../../Components/Statistics/DashboardCard";

interface User {
  _id: string;
  subAdminId: string;
  name: string;
  kyc: false;
  mobile: string;
  email: string;
  password: string;
  state: string;
  city: string;
  balance: number;
  deviceType: string;
  createdOn: string;
  updatedOn: string;
  __v: number;
  totalUserRegisterToday: number;
  totalUserCount: number;
  totalBalance: number;
  liabilities: number;
  totalCountCasino: number;
}

interface CasinoRes {
  totalCountCasino: number;
  totalBetAmountCasino: number;
  casino_profit: number;
  casinoGGR: number;
  qtechGGR: number
  qtechTotalBetAmount: number
  qtechTotalBetCount: number
  qtechTotalBetWinAmount: number
}

interface Sm {
  totalCountSm: number;
  smProfit: number;
  smTotalBetAmount: number;
  smGGr: number;
  sattaMatkaGGR: number
  sattaMatkaTotalBetAmount: number
  sattaMatkaTotalBetCount: number
  sattaMatkaTotalBetPendingAmount: number
  sattaMatkaTotalBetWinAmount: number
}
interface Falcon {
  falconGGR: number
  falconTotalBetAmount: number
  falconTotalBetCount: number
  falconTotalBetPendingAmount: number
  falconTotalBetWinAmount: number
}

interface Exchange {
  totalJtCount: number;
  totalJtBetAmount: number;
  jtProfit: number;
  jtGGR: number;
  jetfairTotalBetAmount: number;
  jetfairTotalBetCount: number;
  jetfairTotalBetPendingAmount: number;
}
function Home() {
  const [registeredUser, setRegisteredUser] = useState<number>(0);
  const [regUserWeb, setRegUserWeb] = useState<number>(0);
  const [regUserApp, setRegUserApp] = useState<number>(0);
  const [regUserWebToday, setRegUserWebToday] = useState<number>(0);
  const [regUserAppToday, setRegUserAppToday] = useState<number>(0);
  const [nonPerformingUser, setNonPerformingUser] = useState<number>(0);
  const [bonusBalanceUser, setBonusBalanceUser] = useState<number>(0);
  const [activeUser, setActiveUser] = useState<number>(0);
  const [usersCreatedToday, setUsersCreatedToday] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [liability, setLiability] = useState<number>(0);
  const [casinoBets, setCasinoBets] = useState<CasinoRes>();
  const [exchange, setExchange] = useState<Exchange>();
  const [smBets, setSmBets] = useState<Sm>();
  const [falconBets, setFalconBets] = useState<Falcon>();
  const [loading, setLoading] = useState<boolean>(false);
  const { User } = useContext(User_Context)

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (token) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_Endpoint}/User/dashboard`,
        headers: {
          Authorization: `Bearer ${User.token}`,
        },
        data: { token: encryptData({}) },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          setRegisteredUser(response.data.data.totalRegisterUsers);
          setRegUserWeb(response.data.data.totalRegisterUsersOfWeb);
          setRegUserApp(response.data.data.totalRegisterUsersOfApp);
          setRegUserAppToday(response.data.data.totalTodayRegisterUsersOfApp);
          setRegUserWebToday(response.data.data.totalTodayRegisterUsersOfWeb);
          setNonPerformingUser(response.data.data.totalNonPerformingUsers);
          setBonusBalanceUser(response.data.data.totalBonusBalanceOfUsers);
          setActiveUser(response.data.data.totalActiveUsers);
          setUsersCreatedToday(response.data.data.totalTodayRegisterUsers);
          setBalance(response.data.data.totalBalanceOfUsers);
          setLiability(response.data.data.falconTotalBetPendingAmount + response.data.data.jetfairTotalBetPendingAmount + response.data.data.sattaMatkaTotalBetPendingAmount);
          setCasinoBets(response.data.data);
          setExchange(response.data.data);
          setSmBets(response.data.data);
          setFalconBets(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
        });
    }
  }, []);
  const casinoBetsData = [
    { label: "Total Bet Amount", value: casinoBets?.qtechTotalBetAmount },
    { label: "Total Bet Count", value: casinoBets?.qtechTotalBetCount },
    { label: "Total Win Amount", value: casinoBets?.qtechTotalBetWinAmount },
    { label: "GGR", value: casinoBets?.qtechGGR },
  ];
  const exchangeBetsData = [
    { label: "Total Bet Amount", value: exchange?.jetfairTotalBetAmount },
    { label: "Total Bet Count", value: exchange?.jetfairTotalBetCount },
    { label: "Total Bet Pending Amount", value: exchange?.jetfairTotalBetPendingAmount },
  ];
  const smBetsData = [
    { label: "Total Bet Amount", value: smBets?.sattaMatkaTotalBetAmount },
    { label: "Total Bet Count", value: smBets?.sattaMatkaTotalBetCount },
    { label: "Total Bet Pending Amount", value: smBets?.sattaMatkaTotalBetPendingAmount },
    { label: "Total Win Amount", value: smBets?.sattaMatkaTotalBetWinAmount },
    { label: "GGR", value: smBets?.sattaMatkaGGR },
  ];
  const falconBetsData = [
    { label: "Total Bet Amount", value: falconBets?.falconTotalBetAmount },
    { label: "Total Bet Count", value: falconBets?.falconTotalBetCount },
    { label: "Total Bet Pending Amount", value: falconBets?.falconTotalBetPendingAmount },
    { label: "Total Win Amount", value: falconBets?.falconTotalBetWinAmount },
    { label: "GGR", value: falconBets?.falconGGR },
  ];
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <Breadcrumbs tab={"Dashboard"} />
            <div
              className="container-fluid py-4"
              style={{ background: "#f8f9fa" }}
            >
              <div className="row">
                <StatCard title={"Total Users"} icon={faUserCircle} value={Math.round(registeredUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Registered Users Web"} icon={faUserCircle} value={regUserWeb} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users App"} icon={faUserCircle} value={regUserApp} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users Today"} icon={faUserCircle} value={usersCreatedToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users Web Today"} icon={faUserCircle} value={regUserWebToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Registered Users App Today"} icon={faUserCircle} value={regUserAppToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Users Balance"} icon={faMoneyBillAlt} value={`₹ ${Math.round(balance)}`} className={"fa fa-user-circle-o mid-icon"} />
                <StatCard title={"Total Users Bonus Balance"} icon={faUserCircle} value={`₹ ${Math.round(bonusBalanceUser)}`} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Active Users"} icon={faUserCircle} value={Math.round(activeUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Non Performing Users"} icon={faUserCircle} value={Math.round(nonPerformingUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Liability"} icon={faShoppingCart} value={`₹ ${Math.round(liability)}`} className={"fa fa-shopping-cart mid-icon"} />
              </div>
              <div className="row mt-4">
                <DashboardCard title="Falcon Bets Today:" data={falconBetsData} />
                <DashboardCard title="Satta Matka Bets Today:" data={smBetsData} />
                <DashboardCard title="Qtech Bets Today:" data={casinoBetsData} />
                <DashboardCard title="Jetfair Bets Today:" data={exchangeBetsData} />
              </div>
              <footer className="footer mt-2  ">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                      <div className="copyright text-center text-sm text-muted text-lg-start">
                        © 2024 kuberbets.vip
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            href="#"
                            className="nav-link text-muted"
                            target="_blank"
                          />
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/presentation"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/"
                            className="nav-link pe-0 text-muted"
                            target="_blank"
                          >
                            
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default Home;