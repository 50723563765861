import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "./Deposit_Withdraw_Providers.css"
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
  // useTheme,
} from "@material-ui/core";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Stateful_Switch from "../../../../Components/Switch/Switch";
import { ToastContainer, toast } from "react-toastify";
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

interface PayinPayout {
  _id: string;
  name: string;
  link: string;
  PaymentType: string;
  serviceName: string;
  status: boolean;
  token: string;
  cookies: string;
  mid: string;
  total: number;
  pendingTotal: number;
  approvedTotal: number;
  rejectedTotal: number;
  reversedTotal: number;
  holdTotal: number;
  state: string[]
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    fontSize: 'small',

  };
}

function GetStylesAdd(name: string, state: readonly string[], theme: Theme) {
  return {
    fontWeight:
      state?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    fontSize: 'small',
  };
}


function PayGmid() {
  const theme = useTheme();
  const [addPopup, setAddPopup] = useState<boolean>(false);
  const [editPopup, setEditPopup] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [nameErrorHelperText, setNameErrorHelperText] = useState<string>("");
  const [mid, setMid] = useState<string>("");
  const [midError, setMidError] = useState<boolean>(false);
  const [midErrorHelperText, setMidErrorHelperText] = useState<string>("");
  const [stateError, setStateError] = useState<boolean>(false);
  const [stateErrorHelperText, setStateErrorHelperText] = useState<string>("");
  const [state, setState] = React.useState<string[]>([]);
  const [Cookie, setCookie] = useState<string>("");
  const [CookieError, setCookieError] = useState<boolean>(false);
  const [CookieErrorHelperText, setCookieErrorHelperText] = useState<string>("");
  const [service, setService] = useState<string>("");
  const [serviceError, setServiceError] = useState<boolean>(false);
  const [serviceErrorHelperText, setServiceErrorHelperText] = useState<string>("");
  const [PaymentType, setPaymentType] = useState<string>("");
  const [paymentTypeError, setPaymentTypeError] = useState<boolean>(false);
  const [paymentErrorHelperText, setPaymentErrorHelperText] =
    useState<string>("");
  const [link, setLink] = useState<string>("");
  const [linkError, setLinkError] = useState<boolean>(false);
  const [linkErrorHelperText, setLinkErrorHelperText] = useState<string>("");
  const [Token, setToken] = useState<string>("");
  const [TokenError, setTokenError] = useState<boolean>(false);
  const [TokenErrorHelperText, setTokenErrorHelperText] = useState<string>("");
  const [updateId, setUpdateId] = useState<string>("");
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<PayinPayout>>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const label = { inputProps: { "aria-label": "Switch demo" } };
  let path = window.location.pathname;
  let subPath = "";
  const { User } = useContext(User_Context);
  const [formMode, setFormMode] = useState("Edit");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const getData = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    const subPath =
      path === "/pay-g-mid" ? "payinAccounts" : "payoutAccounts";

    axios
      .post(`${API_Endpoint}/${subPath}/getAll`, { token: encryptData({}) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data.data = decryptData(response.data.data);
        response.data.data = response.data.data.payload;
        setData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }

  const filterPayout = (startDate: any, endDate: any) => {
    let filter = {}
    if (startDate && endDate) {
      filter = { startDate, endDate }
    }

    setLoading(true);
    const token = localStorage.getItem("token");

    const subPath =
      path === "/pay-g-mid" ? "payinAccounts" : "payoutAccounts";

    axios
      .post(`${API_Endpoint}/${subPath}/getAll`, { token: encryptData(filter) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((response) => {
        response.data.data = decryptData(response.data.data);
        response.data.data = response.data.data.payload;
        setData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData()
  }, [path]);

  const updateProviders = (event: FormEvent) => {
    event?.preventDefault()
    subPath = path == "/pay-g-mid" ? "payinAccounts/" : "payoutAccounts/updateAll/";
    if (!name) {
      setNameError(true);
      setNameErrorHelperText("Enter name");
    }
    else if (!link) {
      setLinkError(true);
      setLinkErrorHelperText("Enter link");
    }
    else if (!mid) {
      setMidError(true)
      setMidErrorHelperText("Enter Mid")
    }
    else if (!Token) {
      setTokenError(true);
      setTokenErrorHelperText("Enter token");
    }
    else if (!Cookie) {
      setCookieError(true);
      setCookieErrorHelperText("Enter cookies");
    }
    else {
      setEditPopup(false);
      setLoading(true);
      const token = localStorage.getItem("token");

      axios
        .post(
          `${API_Endpoint}/${subPath}`,
          {
            token: encryptData({
              _id: updateId,
              link: link,
              status: status,
              token: Token,
              name: name,
              cookies: Cookie,
              mid: mid
            })
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          getData()
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  }


  const addProviders = (event: FormEvent) => {
    subPath = "payinAccounts/"
    event.preventDefault();
    if (!name) {
      setNameError(true);
      setNameErrorHelperText("Enter name");
    } else if (!link) {
      setLinkError(true);
      setLinkErrorHelperText("Enter link");
    } else if (!PaymentType) {
      setPaymentTypeError(true);
      setPaymentErrorHelperText("Enter payment type");
    } else if (!mid) {
      setMidError(true);
      setMidErrorHelperText("Enter mid");
    } else if (!state.length) {
      setStateError(true)
      setStateErrorHelperText("Select state")
    }

    else {
      setAddPopup(false);
      setLoading(true);
      setLink("");
      setName("");
      setMid("")
      setPaymentType("")
      setStatus(false)
      setStateError(false)
      setState([])
      const token = localStorage.getItem("token");

      axios
        .post(
          `${API_Endpoint}/${subPath}`,
          {
            token: encryptData({
              name: name,
              link: link,
              status: status,
              PaymentType: PaymentType,
              mid: mid,
              state: state
            })
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          getData()
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  }
  const handleSubmit = (event: FormEvent) => {

    if (formMode === "Edit") {
      updateProviders(event);
    } else {
      subPath = "payoutAccounts/";
      event.preventDefault();
      if (!name) {
        setNameError(true);
        setNameErrorHelperText("Enter name");
      } else if (!link) {
        setLinkError(true);
        setLinkErrorHelperText("Enter link");
      } else if (!mid) {
        setMidError(true);
        setMidErrorHelperText("Enter mid");
      } else if (!Token) {
        setTokenError(true);
        setTokenErrorHelperText("Enter token");
      } else if (!Cookie) {
        setCookieError(true);
        setCookieErrorHelperText("Enter cookie");
      }
      // else if (!service) {
      //   setServiceError(true);
      //   setServiceErrorHelperText("Enter service name");
      // }
      else {
        setAddPopup(false);
        setLoading(true);
        setLink("");
        setName("");
        setToken("")
        setPaymentType("")
        setStatus(false)
        const token = localStorage.getItem("token");

        axios
          .post(
            `${API_Endpoint}/${subPath}`,
            {
              token: encryptData({
                name: name,
                link: link,
                status: status,
                token: Token,
                cookies: Cookie,
                mid: mid
              })
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            getData()
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            setLoading(false);
          });
      }
    }
  };

  const handleSubmitData = (e: FormEvent) => {
    if (path === "/pay-g-mid") {
      addProviders(e)
    } else {
      handleSubmit(e)
    }
  }
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    subPath = path == "/pay-g-mid" ? "payinAccounts/" : "payoutAccounts/";
    setLoading(true);
    const status = event.target.checked;
    const _id = event.target.id;
    const token = localStorage.getItem("token");
    let obj: any = {
      _id,
      status,
      name
    }

    axios
      .post(
        `${API_Endpoint}/${subPath}/update`,
        {
          token: encryptData(obj)
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        const updatedStatusData = data.map((item) => {
          if (item._id === _id) {
            return {
              ...item,
              status: status,
            };
          } else if (item.name === name && item._id !== _id) {
            return {
              ...item,
              status: false,
            };
          } else {
            return item;
          }
        });

        setData(updatedStatusData);
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameError(false);
  };

  const handleMid = (event: ChangeEvent<HTMLInputElement>) => {
    setMid(event.target.value);
    setMidError(false);
  };
  const handleLink = (event: ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
    setLinkError(false);
  };
  const handlePaymentType = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
    setPaymentTypeError(false);
  };
  const handleSetToken = (event: ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
    setTokenError(false);
  };
  const handleSetCookie = (event: ChangeEvent<HTMLInputElement>) => {
    setCookie(event.target.value);
    setCookieError(false);
  };
  const handleSetService = (event: ChangeEvent<HTMLInputElement>) => {
    setService(event.target.value);
    setServiceError(false);
  };
  const deleteGateway = () => {
    subPath = path == "/pay-g-mid" ? "payinAccounts" : "payoutAccounts";
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");

    axios
      .post(`${API_Endpoint}/${subPath}/delete`, { token: encryptData({ _id: id }) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const updatedData = data.filter((item) => item._id !== id);
        setData(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const openDeletePopup = (id: string) => {
    setDeletePopup(true);
    setId(id);
  };
  const openEditPopup = (item: any) => {

    setEditPopup(true);
    setName(item.name)
    setLink(item.link)
    setStatus(item.status)
    setPaymentType(item.PaymentType)
    setToken(item.token)
    setFormMode("Edit");
    setUpdateId(item._id)
    setCookie(item.cookies ? item.cookies : "Not Available")
    setMid(item.mid)
    //setService(item.serviceName ? item.serviceName : "Not Available")
  };

  const openAddDialog = () => {
    setMid("")
    setName("")
    setLink("");
    setStatus(false);
    setPaymentType("")
    setToken("")
    setTokenError(false)
    setTokenErrorHelperText("")
    setAddPopup(true)
    setFormMode("");
    setCookie("")
    // setService("")
    setServiceError(false)
    setServiceErrorHelperText("")
  };

  const clearDate = () => {
    setStartDate('')
    setEndDate('')
    // getData()
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [personName, setPersonName] = React.useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<string[]>, mid: string) => {
    const {
      target: { value },
    } = event;

    if (!value.includes("undefined")) {
      setPersonName(
        typeof value === 'string' ? value.split(',') : value,
      );
      const updatedData: any = data.map((item) => {
        if (item.mid === mid) {
          return { ...item, state: typeof value === 'string' ? value.split(',') : value };
        }
        return item;
      });
      setData(updatedData);
    }
  };

  const names = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Ladakh",
    "Jammu and Kashmir",
    "Puducherry"
  ]

  const addState = (e: FormEvent, id: string) => {
    e.preventDefault()
    setLoading(true);
    const token = localStorage.getItem("token");
    const subPath = "payinAccounts"
    axios
      .post(`${API_Endpoint}/${subPath}/updateState`, { token: encryptData({ _id: id, state: personName }) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast("State updated successfully!")
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }

  const handleChangeAdd = (event: SelectChangeEvent<typeof state>) => {
    const {
      target: { value },
    } = event;
    setState(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={path === "/pay-g-mid" ? "Deposit Providers" : "Withdrawal Providers"} button={(path === "/pay-g-mid" && User.data.Responsibilities.includes(Responsibilities.Add_PayIn_Account)) || (path === '/payout-accounts' && User.data.Responsibilities.includes(Responsibilities.Add_PayOut_Account)) ? <Button onClick={openAddDialog} className="btn-payment" variant="contained">Add</Button> : ''} />
              <div className="container-fluid">
                <div className="row tp-form">
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl"></label>
                    <a onClick={() => filterPayout(startDate, endDate)} className="sechBtn mt-1" >
                      Apply
                    </a>
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl"></label>
                    <a onClick={clearDate} className="sechBtn mt-1">
                      Clear Dates
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <Dialog open={addPopup} onClose={() => setAddPopup(false)}>
                  {/* <DialogTitle>Payment Form</DialogTitle> */}
                  <DialogContent>
                    <form onSubmit={(e) => handleSubmitData(e)}>
                      <div>
                        <Reusable_Input type={"text"} label={"Company Name"} fullWidth={true} value={name} error={nameError} helperText={nameErrorHelperText} onChange={handleName} />
                      </div>
                      <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Link"} fullWidth={true} value={link} error={linkError} helperText={linkErrorHelperText} onChange={handleLink} />
                      </div>
                      {path === "/pay-g-mid" ? <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Payment Type"} fullWidth={true} value={PaymentType} error={paymentTypeError} helperText={paymentErrorHelperText} onChange={handlePaymentType} />
                      </div> : ""}
                      {/* <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Payment Type"} fullWidth={true} value={PaymentType} error={paymentTypeError} helperText={paymentErrorHelperText} onChange={handlePaymentType} />
                      </div> */}
                      <div className="mt-2 mb-2">
                        <Reusable_Input type={"text"} label={"Mid"} fullWidth={true} value={mid} error={midError} helperText={midErrorHelperText} onChange={handleMid} />
                      </div>
                      {/* {path === "/pay-g-mid" ? <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Mid"} fullWidth={true} value={mid} error={midError} helperText={midErrorHelperText} onChange={handleMid} />
                      </div> : ""} */}
                      {
                        path === "/pay-g-mid" ?
                          <div>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="demo-multiple-name-label">State</InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={state}
                                onChange={handleChangeAdd}
                                input={<OutlinedInput label="Chip" />}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={GetStylesAdd(name, state, theme)}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>

                            </FormControl>
                          </div>
                          :
                          ""
                      }
                      {
                        path === "/pay-g-mid" && stateError ?
                          <div style={{ fontSize: "8px", color: "red" }}><p>select state</p></div>
                          :
                          ""
                      }

                      {path === "/payout-accounts" ? (<>
                        <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Token"} fullWidth={true} value={Token} error={TokenError} helperText={TokenErrorHelperText} onChange={handleSetToken} />
                        </div>
                        <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Cookies"} fullWidth={true} value={Cookie} error={CookieError} helperText={CookieErrorHelperText} onChange={handleSetCookie} />
                        </div>
                        {/* <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Service Name"} fullWidth={true} value={service} error={serviceError} helperText={serviceErrorHelperText} onChange={handleSetService} />
                        </div> */}
                      </>)
                        : <></>}

                      {/* <FormGroup className="mt-2">
                        {path === "/pay-g-mid" ? "" : <Stateful_Switch label={status ? "Active" : "Inactive"} value={status} onChange={setStatus} />}
                      </FormGroup> */}
                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => { return setAddPopup(false), setState([]) }}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog open={editPopup} onClose={() => setEditPopup(false)}>
                  {/* <DialogTitle>Payment Form</DialogTitle> */}
                  <DialogContent>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <Reusable_Input type={"text"} label={"Company Name"} fullWidth={true} value={name} error={nameError} helperText={nameErrorHelperText} onChange={handleName} />
                      </div>
                      <div className="mt-2">
                        <Reusable_Input type={"text"} label={"Link"} fullWidth={true} value={link} error={linkError} helperText={linkErrorHelperText} onChange={handleLink} />
                      </div>

                      {path === "/payout-accounts" ? (<>
                        <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Mid"} fullWidth={true} value={mid} error={midError} helperText={midErrorHelperText} onChange={handleMid} />
                        </div>
                        <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Token"} fullWidth={true} value={Token} error={TokenError} helperText={TokenErrorHelperText} onChange={handleSetToken} />
                        </div>
                        <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Cookies"} fullWidth={true} value={Cookie} error={CookieError} helperText={CookieErrorHelperText} onChange={handleSetCookie} />
                        </div>
                        {/* <div className="mt-2">
                          <Reusable_Input type={"text"} label={"Service Name"} fullWidth={true} value={service} error={serviceError} helperText={serviceErrorHelperText} onChange={handleSetService} />
                        </div> */}
                      </>)

                        : <></>}

                      {/* <FormGroup className="mt-2">
                        <Stateful_Switch label={status ? "Active" : "Inactive"} value={status} onChange={setStatus} />
                      </FormGroup> */}
                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setEditPopup(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th>Gateway Name</th>
                            {/* {path === "/pay-g-mid" ? <th>Mid</th> : ""} */}
                            <th>Mid</th>
                            {/* {path === "/pay-g-mid" ? <th>Total Amount</th> : ""} */}
                            <th>Total Amount</th>
                            <th>Link</th>
                            {path === '/pay-g-mid' ?
                              <th>State</th>
                              :
                              ""
                            }
                            {path === "/payout-accounts" ? <th>Token</th> : ""}
                            {path === "/payout-accounts" ? <th>Cookies</th> : ""}
                            {/* <th>Payment type</th> */}
                            {path === "/pay-g-mid" ? <th>Payment type</th> : ""}
                            {/* {path === "/payout-accounts" ? <th>Service Name</th> : ""} */}
                            {(path === "/pay-g-mid" && User.data.Responsibilities.includes(Responsibilities.Toggle_PayIn_Account)) || (path === '/payout-accounts' && User.data.Responsibilities.includes(Responsibilities.Toggle_PayOut_Account)) ? <th>Status</th> : ''}
                            {(path === "/pay-g-mid" && User.data.Responsibilities.includes(Responsibilities.Toggle_PayIn_Account)) || (path === '/payout-accounts' && User.data.Responsibilities.includes(Responsibilities.Toggle_PayOut_Account)) ? <th>Action</th> : ''}
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item) => {
                            return (
                              <tr id={item._id}>
                                <td>{item.name}</td>
                                {/* {path === "/pay-g-mid" ? <td>{item.mid}</td> : ""} */}
                                <td>{item.mid}</td>
                                {/* {path === "/pay-g-mid" ? <td>{item.total}</td> : ""} */}
                                {
                                  path === "/pay-g-mid" ?
                                    <td>
                                      Approved - <b>{item.approvedTotal}</b> <br />
                                      Pending -  <b>{item.pendingTotal}</b>
                                    </td>
                                    :
                                    <td>
                                      Approved - <b>{item.approvedTotal}</b>,
                                      Pending - <b>{item.pendingTotal}</b><br />
                                      Rejected - <b>{item.rejectedTotal}</b>,
                                      Reversed - <b>{item.reversedTotal}</b><br />
                                      On hold - <b>{item.holdTotal}</b>
                                    </td>
                                }
                                <td>{item.link}</td>
                                {path === "/payout-accounts" ? <td>{item.token ? item.token : "Not Available"}</td> : ""}
                                {path === "/payout-accounts" ? <td>{item.cookies ? item.cookies : "Not Available"}</td> : ""}
                                {/* <td>{item.PaymentType ? item.PaymentType : "Not Available"}</td> */}
                                {path === '/pay-g-mid' ?
                                  <td>
                                    <div>
                                      <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="demo-multiple-name-label">State</InputLabel>
                                        <Select
                                          labelId="demo-multiple-chip-label"
                                          id="demo-multiple-chip"
                                          multiple
                                          value={Array.isArray(item.state) ? item.state : []}
                                          onChange={(e) => handleChange(e, item.mid)}
                                          input={<OutlinedInput label="Chip" />}
                                          MenuProps={MenuProps}
                                          onClose={getData}
                                        >
                                          {names.map((name) => (
                                            <MenuItem
                                              key={name}
                                              value={name}
                                              style={getStyles(name, item.state, theme)}
                                            >
                                              {name}
                                            </MenuItem>
                                          ))}
                                          <Button className="dwp_btn" value={"undefined"}
                                            onClick={(e) => addState(e, item._id)}
                                          >
                                            submit
                                          </Button>
                                        </Select>

                                      </FormControl>
                                    </div>
                                  </td>
                                  :
                                  ""
                                }
                                {path === "/pay-g-mid" ? <td>{item.PaymentType ? item.PaymentType : "Not Available"}</td> : ""}
                                {/* {path === "/payout-accounts" ? <td>{item.serviceName ? item.serviceName : "Not Available"}</td> : ""} */}
                                {(path === "/pay-g-mid" && User.data.Responsibilities.includes(Responsibilities.Toggle_PayIn_Account)) || (path === '/payout-accounts' && User.data.Responsibilities.includes(Responsibilities.Toggle_PayOut_Account)) ? <td>
                                  <Switch
                                    {...label}
                                    id={item._id}
                                    checked={item.status}
                                    onChange={(event) => handleStatusChange(event, item.name)}
                                  />
                                </td> : ''}
                                <td>
                                  {(path === "/pay-g-mid" && User.data.Responsibilities.includes(Responsibilities.Delete_PayIn_Account)) || (path === '/payout-accounts' && User.data.Responsibilities.includes(Responsibilities.Delete_PayOut_Account)) ? <span>
                                    <FontAwesomeIcon
                                      id={item._id}
                                      className="fa fa-pencil-square icon-home icon-trash "
                                      icon={faTrashCan}
                                      onClick={() => openDeletePopup(item._id)}
                                      style={{ cursor: "pointer" }}
                                    />
                                    {path === "/payout-accounts" ? <FontAwesomeIcon
                                      id={item._id}
                                      className="fa fa-pencil-square icon-home icon-trash "
                                      icon={faPencilSquare}
                                      onClick={() => openEditPopup(item)}
                                      style={{ cursor: "pointer" }}
                                    /> : ""}
                                  </span> : ''}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <ul className="pagination">
                      <li className="page-item">
                        <a className="page-link page-link-prev" href="#">
                          Previous
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item active">
                        <a className="page-link " href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#">
                          Next
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © 2024 kuberbets.vip
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
          <div>
            <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
              <DialogTitle>Are You Sure ?</DialogTitle>
              <DialogContent>
                <DialogActions className="mt-n3">
                  <Button
                    className="btn-popup"
                    variant="outlined"
                    onClick={() => setDeletePopup(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-popup"
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={() => deleteGateway()}
                  >
                    Delete
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}

export default PayGmid;