import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useState } from "react";

type Props_Type = {
  Callback?: Function;
  Label: string;
  Value?: boolean;
};

 const Stateful_Checkbox = ({ Callback, Label, Value }: Props_Type): JSX.Element => {
  const [Checked, Set_Checked] = useState<boolean>(Value === undefined ? false : Value);

  const Handle_On_Change = () =>
  {
    Set_Checked (!Checked);
    if (Callback)
    {
      Callback (!Checked);
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox onChange={Handle_On_Change} checked={Checked} />
      }
      label={Label}
    />
  );
};
export default Stateful_Checkbox

