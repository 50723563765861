import React from 'react';

function TableFiltersSelectBox({ onChange, value, options }: TableFiltersSelectBoxProps) {
    return (
        <select
            name="cr-dr"
            id="cr-dr"
            className='table-filters-select-box'
            onChange={(e) => onChange(e)}
            value={value}
        >
            <option value="">Select</option>
            {options.map((item: any, index: number) => (
                <option key={index} value={item.id}>{item.value}</option>
            ))}

        </select>
    )
}

export default TableFiltersSelectBox;

interface TableFiltersSelectBoxProps {
    onChange: any
    value: string
    options: any
}
