import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import './Users.css'
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Button from "@mui/material/Button";
import { Pagination, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Responsibilities, User_Types } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import Reusable_Input from "../../../../Components/InputField/InputField";
import SearchBar from "../../../../Components/SearchBox/Search";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import { dateTime, formatDate, formatedTime } from "../../../../utils/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";

const Users = () => {
	const [registerPopup, setRegisterPopup] = useState<boolean>(false);
	const [editRegisterPopup, setEditRegisterPopup] = useState<boolean>(false);
	const [searchName, setSearchName] = useState<string>("");
	const [itemsPerPage, setItemsPerPage] = useState<number>(10);
	const [searchMob, setSearchMob] = useState<string>("");
	const [searchEmail, setSearchEmail] = useState<string>("");
	const [searchAddress, setSearchAddress] = useState<string>("");
	const [subAdminId, setSubAdminId] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [nameHint, setNameHint] = useState<string>("");
	const [nameError, setNameError] = useState<boolean>(false);
	const [mobile, setMobile] = useState<string>("");
	const [mobileHint, setMobileHint] = useState<string>("");
	const [mobileError, setMobileError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [emailHint, setEmailHint] = useState<string>("");
	const [emailError, setEmailError] = useState<boolean>(false);
	const [password, setPassword] = useState<string>("");
	const [passwordHint, setPasswordHint] = useState<string>("");
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [selectedRoleHint, setSelectedRoleHint] = useState<string>("");
	const [state, setState] = useState<[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [users, setusers] = useState([]);
	const [totalUser, setTotalUser] = useState<number>(0)
	const { User } = useContext(User_Context);
	const Navigate = useNavigate();
	const [Roles, Set_Roles] = useState([]);
	const [Selected_User_Type, Set_Selected_User_Type] = useState('');
	const [Selected_Role, Set_Selected_Role] = useState('');
	const [roleId, setRoleId] = useState('');
	const [Select_User_Type_Error_Status, Set_Select_User_Type_Error_Status] = useState(false);
	const [selectUserEditError, setSelectUserEditError] = useState(false);
	const [Select_Role_Error_Status, Set_Select_Role_Error_Status] = useState(false);
	const [Sub_Admins, Set_Sub_Admins] = useState([]);
	const [activeUsers, setActiveUsers] = useState([]);
	const [inActiveUsers, setInActiveUsers] = useState([]);
	const [Current_User_Type, Set_Current_User_Type] = useState<string>(User_Types.User);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [permissionButton, setPermissionButton] = useState<boolean>(false)


	const Change_User_Type = (Event_Object: SelectChangeEvent<string>) => {
		Set_Selected_User_Type(Event_Object.target.value);
		Set_Select_User_Type_Error_Status(false);
	}

	const Change_Role = (Event_Object: SelectChangeEvent<string>) => {
		Set_Selected_Role(Event_Object.target.value);
		Set_Select_Role_Error_Status(false);
	}
	const ChangeEditRole = (Event_Object: SelectChangeEvent<string>) => {
		setRoleId(Event_Object.target.value);
		setSelectUserEditError(false);
	}

	const handleName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
		setNameHint("");
	};

	const handleSearchName = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchName(event.target.value);
	};
	const handleSearchMob = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchMob((event.target.value));
	};
	const handleSearchEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchEmail(event.target.value);
	};
	const handleSearchAddress = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchAddress(event.target.value);
	};
	const handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
		setMobile(event.target.value);
		setMobileError(false);
		setMobileHint("");
	};
	const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailError(false);
		setEmailHint("");
	};
	const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordError(false);
		setPasswordHint("");
	};




	const handlePerPage = (newValue: any) => {
		setItemsPerPage(newValue)
	};

	const getAllUsers = () => {
		setLoading(true);
		const filter: { email?: string, mobile?: string, name?: string, city?: string } = {};
		if (searchEmail) {
			filter.email = searchEmail;
		}
		if (searchMob) {
			filter.mobile = searchMob;
		}
		if (searchName) {
			filter.name = searchName;
		}
		if (searchAddress) {
			filter.city = searchAddress;
		}
		let data = {}
		if (startDate && endDate) {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: currentPage,
				filter: filter,
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: currentPage,
				filter: filter
			};
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/getAll`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};

		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data)
			let data = response.data.data.payload.items.length <= 0
			if (data) {
				toast("No users registered for todays date")
			}
			setusers(response.data.data.payload.items);
			setTotalPages(response.data.data.payload.totalPages);
			setTotalUser(response.data.data.payload.total)
			setLoading(false);
		}).catch((error) => {
			console.log(error);
		});
	};
	const getAllSubAdmins = () => {
		let config = {
			method: "post",
			url: `${API_Endpoint}/SubAdmin/getAll`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: {
				token: encryptData({
					itemsPerPage: itemsPerPage,
					pageNo: 1,
					filter: {}
				})
			},
		};

		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			Set_Sub_Admins(response.data.data.payload);
			setTotalUser(response.data.data.payload.length)

		}).catch((error) => {
			console.log(error);
		});
	}

	const getActiveUsers = () => {
		setLoading(true)
		let payload: any = {
			itemsPerPage: itemsPerPage,
			pageNo: currentPage,
			filter: {
			},
			Text:Current_User_Type
		}
		if (startDate != "" && endDate != "") {
			payload.startDate = startDate
			payload.endDate = endDate
		}
		let config = {
			method: "post",
			url: `${API_Endpoint}/User/get-active-customers`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: {
				token: encryptData(payload)
			},
		};
		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			setLoading(false)
			setActiveUsers(response.data.data.payload.data);
			setInActiveUsers(response.data.data.payload.data);
			setTotalPages(response.data.data.payload.pageNo)
			setTotalUser(response.data.data.payload.total)
		}).catch((error) => {
			console.log(error);
		});

	}
	const getData = () => {
		if (Current_User_Type === "activeUser" || Current_User_Type === "Non_Performing_User") {
			getActiveUsers()
		} else if (Current_User_Type === "User") {
			getAllUsers()
		} else if (Current_User_Type === "Sub_Admin") {
			getAllSubAdmins()
		}
	}

	useEffect(() => {
		getData()
	}, [currentPage, itemsPerPage, Current_User_Type]);

	useEffect(() => {
		getStateAndCity();
		getRoles()
	}, []);
	const getRoles = () => {
		let config = {
			method: "post",
			url: `${API_Endpoint}/roles`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData({}) },
		};

		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			const roles = response.data.data.payload
			const foundRole: any = roles.find((item: any) => item._id as string === User.data.Role_ID)
			if (foundRole && foundRole.Name === 'Full Access') {
				setPermissionButton(true)
				User.data.permission = true
			}
			Set_Roles(response.data.data.payload);
		}).catch((error) => {
			console.log(error);
		});
	}


	const getStateAndCity = () => {
		axios
			.request({
				url: `${API_Endpoint}/Country`,
				method: "get",
				headers: { Authorization: `Bearer ${User.token}` },
			})
			.then((response) => {
				setState(response.data.data[0].states);
				// setLoading(false);
			})
			.catch((err: any) => {
				setLoading(false);
				console.log(`${err.message}`);
			});
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (!name) {
			setNameError(true);
			setNameHint("enter name");
		}
		else if (!mobile) {
			setMobileError(true);
			setMobileHint("please enter mobile");
		}
		else if (!email) {
			setEmailError(true);
			setEmailHint("please enter email");
		}
		else if (!password) {
			setPasswordError(true);
			setPasswordHint("please enter password");
		}
		else if (!Selected_User_Type) {
			Set_Select_User_Type_Error_Status(true);
			setSelectedRoleHint("please enter role");
		}
		else if (!Selected_Role && Selected_User_Type === User_Types.Sub_Admin) {
			Set_Select_Role_Error_Status(true);
			setSelectedRoleHint("please enter role");
		}
		else {
			let data = Selected_User_Type === User_Types.Sub_Admin ? {
				name: name,
				mobile: mobile,
				email: email,
				password: password,
				Role_ID: Selected_Role
			} :
				{
					name: name,
					city: 'Unknown',
					state: 'Unknown',
					balance: 0,
					profitLoss: 0,
					mobile: mobile,
					email: email,
					password: password
				};
			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: Selected_User_Type === User_Types.Sub_Admin ? `${API_Endpoint}/SubAdmin` : `${API_Endpoint}/User`,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${User.token}`,
				},
				data: { token: encryptData(data) },
			};

			axios.request(config).then((response) => {
				if (response.data.success === true) {
					toast("User Registered Successfully");
				}
				setLoading(false);
				setRegisterPopup(false);
				setName("");
				setEmail("");
				setMobile("");
				Set_Selected_Role("");
				setPassword("");
				if (Selected_User_Type === User_Types.User) {
					setusers([data as never, ...users]);
				}
				if (Selected_User_Type === User_Types.Sub_Admin) {
					Set_Sub_Admins([data as never, ...Sub_Admins]);
				}
				if (Selected_User_Type === User_Types.Active_User) {
					setActiveUsers([data as never, ...activeUsers]);
				}
				if (Selected_User_Type === User_Types.Non_Performing_User) {
					setInActiveUsers([data as never, ...inActiveUsers]);
				}
				Set_Selected_User_Type('');
			}).catch((error) => {
				console.log(error);
				setLoading(false);
			});
		}
	};

	const handleUpdateSudAdmin = (event: FormEvent) => {
		event.preventDefault()
		let data = {
			subAdminId: subAdminId,
			updatedBy: User.data._id,
			roleId: roleId
		}
		let config = {
			method: "post",
			url: `${API_Endpoint}/SubAdmin/update-subAdmin-role`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: {
				token: encryptData(data)
			},
		};
		axios.request(config).then((response) => {
			getAllSubAdmins()
			setEditRegisterPopup(false)
		}).catch((error) => {
			console.log(error);
		});
	}

	const handleCloseRegisterPopup = () => {
		setRegisterPopup(false);
		setName("");
		setEmail("");
		setMobile("");
		setSelectedRoleHint("");
		setPassword("");
	};
	const handleCloseEditRegisterPopup = () => {
		setEditRegisterPopup(false);
	};
	const clearDates = () => {
		setStartDate('')
		setEndDate('')
	}
	const handleEditSubAdmin = (id: string) => {
		setSubAdminId(id)
		setEditRegisterPopup(true)
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="g-sidenav-show  bg-gray-100">
					<ToastContainer autoClose={2000} position="top-center" />
					<Sidenav />
					<main className="main-content position-relative">
						<div style={{ background: "#f8f9fa" }}>
							<Breadcrumbs tab={"Users"} />
							<div className="container-fluid">
								<div className="row">
									<div className="row tp-form mb-1" style={{ display: 'flex' }}>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">From Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="From Date"
												value={startDate}
												onChange={(e) => setStartDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">To Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="To Date"
												value={endDate}
												onChange={(e) => setEndDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={getData} className="sechBtn mt-1">
												Apply
											</a>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={clearDates} className="sechBtn mt-1">
												clear dates
											</a>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
											<label className="lbl">Select User Type</label>
											<Stateful_Select
												value={Current_User_Type}
												onChange={(newValue) => {
													Set_Current_User_Type(newValue)
												}}
												options={[User_Types.User, User_Types.Sub_Admin, User_Types.Active_User,User_Types.Non_Performing_User]}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
											<label className="lbl">Items Per Page</label>
											<Stateful_Select
												// label="Items Per Page"
												value={itemsPerPage.toString()}
												onChange={(newValue: any) => handlePerPage(newValue)}
												options={["10", "25", "50", "75", "100"]}
											/>
										</div>
										{User.data.Responsibilities.includes(Responsibilities.Register_New_User) && <div className="col-6 col-xl-2 col-sm-4 pdrt reg mt-1 role-mob2 d-flex align-items-center" style={{ width: "100px" }}>
											<label className="lbl"></label>
											<a onClick={() => setRegisterPopup(true)} className="sechBtn apply-btn">Register</a>
										</div>}
										<div className="col-6 col-xl-2 col-sm-4 pdrt reg mt-1 role-mob2 d-flex align-items-center mt-2">
											<label className="lbl"></label>
											<b>Total user count : {totalUser}</b>
										</div>
									</div>
									<div>
										<Dialog
											open={registerPopup}
											onClose={() => setRegisterPopup(false)}
										>
											<DialogContent>
												<form>
													<div>
														<Reusable_Input label={"Full Name"} value={name} error={nameError} helperText={nameHint} onChange={handleName} type={"text"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Mobile"} value={mobile} error={mobileError} helperText={mobileHint} onChange={handleMobile} type={"text"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Email"} value={email} error={emailError} helperText={emailHint} onChange={handleEmail} type={"email"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Password"} value={password} error={passwordError} helperText={passwordHint} onChange={handlePassword} type={"password"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<FormControl
															variant="outlined"
															fullWidth
															size="small"
														>
															<InputLabel>Select User Type</InputLabel>
															<Select
																MenuProps={{ disablePortal: true }}
																labelId="demo-select-small-label"
																id="demo-select-small"
																label="Select role"
																value={Selected_User_Type}
																onChange={Change_User_Type}
																error={Select_User_Type_Error_Status}
															>
																<MenuItem value={User_Types.User}>User</MenuItem>
																<MenuItem value={User_Types.Sub_Admin}>Sub-Admin</MenuItem>
															</Select>
														</FormControl>
													</div>
													{Selected_User_Type === User_Types.Sub_Admin &&
														<div className="mt-2">
															<FormControl
																variant="outlined"
																fullWidth
																size="small"
															>
																<InputLabel>Select Role</InputLabel>
																<Select
																	labelId="demo-select-small-label"
																	id="demo-select-small"
																	label="Select role"
																	value={Selected_Role}
																	onChange={Change_Role}
																	error={Select_Role_Error_Status}
																>
																	{Roles.map((Role: any) => <MenuItem key={Role._id} value={Role._id}>{Role.Name}</MenuItem>)}
																</Select>
															</FormControl>
														</div>
													}
													<div className="mt-2">
														<DialogActions>
															<Button
																className="btn-popup"
																variant="outlined"
																onClick={handleCloseRegisterPopup}
																color="primary"
															>
																Cancel
															</Button>
															<Button
																className="btn-popup"
																variant="outlined"
																type="submit"
																color="primary"
																onClick={handleSubmit}
															>
																Register
															</Button>
														</DialogActions>

													</div>
												</form>
											</DialogContent>
										</Dialog>
									</div>
									<div>
										<Dialog
											open={editRegisterPopup}
											onClose={() => setEditRegisterPopup(false)}
										>
											<DialogContent>
												<form>
													<div className="mt-2">
														<FormControl
															variant="outlined"
															fullWidth
															size="small"
															onClick={handleSubmit}
														>
															<InputLabel>Select Role</InputLabel>
															<Select
																labelId="demo-select-small-label"
																id="demo-select-small"
																label="Select role"
																value={roleId}
																onChange={ChangeEditRole}
																error={selectUserEditError}
															>
																{Roles.map((Role: any) => <MenuItem key={Role._id} value={Role._id}>{Role.Name}</MenuItem>)}
															</Select>
														</FormControl>
													</div>

													<div className="mt-2">
														<DialogActions>
															<Button
																className="btn-popup"
																variant="outlined"
																onClick={handleCloseEditRegisterPopup}
																color="primary"
															>
																Cancel
															</Button>
															<Button
																className="btn-popup"
																variant="outlined"
																type="submit"
																color="primary"
																onClick={handleUpdateSudAdmin}
															>
																Submit
															</Button>
														</DialogActions>
													</div>
												</form>
											</DialogContent>
										</Dialog>
									</div>
									<div className="col-12 mt-2">
										<div className="table-responsive">
											{
												Current_User_Type === User_Types.User && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>City</th>
															<th>Balance</th>
															<th>Date</th>
															<th>Time</th>
															<th>Wager</th>
															<th>Bonus Points</th>
														</tr>
													</thead>
													<thead>
														<tr className="bg-table">
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchName}
																		onChange={handleSearchName}
																		onSearch={getAllUsers}
																		placeholder="Search by name"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchMob}
																		onChange={handleSearchMob}
																		onSearch={getAllUsers}
																		placeholder="Search by mobile"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchEmail}
																		onChange={handleSearchEmail}
																		onSearch={getAllUsers}
																		placeholder="Search by email"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchAddress}
																		onChange={handleSearchAddress}
																		onSearch={getAllUsers}
																		placeholder="Search by city"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
														</tr>
													</thead>
													<tbody>
														{users?.map((User: any, index:number) => (
															<tr>
                                                                <td>{index + 1}</td>
																<td key={User._id} onClick={() => {
																	const url = `/user-report/${User._id}/${User.name}`;
																	Navigate(url);
																	// window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{User.name}
																</td>
																<td>
																	+{User.countryCode || 91}
																	{User.mobile}
																</td>
																<td>{User.email}</td>
																<td>
																	{User.country}, {User.state}, {User.city},{" "}
																	{User.street}, {User.homeNo}, {User.postCode}
																</td>
																<td>{Math.floor(User.balance)}</td>
																<td>
																	{formatDate(User.createdOn)}
																</td>
																<td>
																	{formatedTime(User.createdOn)}
																</td>
																<td>{User.wager}</td>
																<td>{User.bonusPoints}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											{
												Current_User_Type === User_Types.Sub_Admin && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>Role</th>
														</tr>
													</thead>
													<tbody>
														{Sub_Admins?.map((Sub_Admin: any, index:number) => (
															<tr key={Sub_Admin._id}>
                                                                <td>{index + 1}</td>
																<td>
																	{Sub_Admin.name}
																</td>
																<td>
																	+{Sub_Admin.countryCode || 91}{Sub_Admin.mobile}
																</td>
																<td>{Sub_Admin.email}</td>
																<td>{Roles.find((Role: any) => Role._id === Sub_Admin.Role_ID) !== undefined ? (Roles.find((Role: any) => Role._id === Sub_Admin.Role_ID) as any).Name : ''}
																	{


																		permissionButton ?
																			<FontAwesomeIcon
																				className="fa fa-pencil-square icon-home icon-banner"
																				icon={faPencilSquare}
																				style={{ cursor: "pointer" }}
																				onClick={(id) => handleEditSubAdmin(Sub_Admin._id)}
																			/> : ""
																	}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											{
												Current_User_Type === User_Types.Active_User && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>Balance</th>
															<th>Date</th>
															<th>Time</th>
														</tr>
													</thead>
													<tbody>
														{activeUsers?.map((active: any, index:number) => (
															<tr key={active._id}>
                                								<td>{index + 1}</td>
																<td onClick={() => {
																	const url = `/user-report/${active._id}/${active.name}`;
																	window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{active.name}
																</td>
																<td>
																	+{active.countryCode || 91}{active.mobile}
																</td>
																<td>{active.email}</td>
																<td>{Math.floor(active.balance)}</td>
																<td>{formatDate(active.createdOn)}</td>
																<td>{formatedTime(active.createdOn)}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											{
												Current_User_Type === User_Types.Non_Performing_User && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>Balance</th>
															<th>Date</th>
															<th>Time</th>
														</tr>
													</thead>
													<tbody>
														{inActiveUsers?.map((active: any, index:number) => (
															<tr key={active._id}>
                                								<td>{index + 1}</td>
																<td onClick={() => {
																	const url = `/user-report/${active._id}/${active.name}`;
																	window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{active.name}
																</td>
																<td>
																	+{active.countryCode || 91}{active.mobile}
																</td>
																<td>{active.email}</td>
																<td>{Math.floor(active.balance)}</td>
																<td>{formatDate(active.createdOn)}</td>
																<td>{formatedTime(active.createdOn)}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										</div>
										{Current_User_Type === User_Types.Active_User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPages}
													color="secondary"
													page={currentPage}
													onChange={(Event, New_Page) =>
														setCurrentPage(New_Page)
													}
												/>
											</Stack>
										</ul>}
										{Current_User_Type === User_Types.Non_Performing_User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPages}
													color="secondary"
													page={currentPage}
													onChange={(Event, New_Page) =>
														setCurrentPage(New_Page)
													}
												/>
											</Stack>
										</ul>}
										{Current_User_Type === User_Types.User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPages}
													color="secondary"
													page={currentPage}
													onChange={(Event, New_Page) =>
														setCurrentPage(New_Page)
													}
												/>
											</Stack>
										</ul>}
									</div>
								</div>
								<footer className="footer">
									<div className="container-fluid">
										<div className="row align-items-center justify-content-lg-between">
											<div className="col-lg-6 mb-lg-0  ">
												<div className="copyright text-center text-sm text-muted text-lg-start">
													© 2024 kuberbets.vip
												</div>
											</div>
											<div className="col-lg-6">
												<ul className="nav nav-footer justify-content-center justify-content-lg-end">
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															Home
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															
														</a>
													</li>
													<li className="nav-item">
														<a
															className="nav-link pe-0 text-muted"
															target="_blank"
														>
															
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</footer>
							</div>
						</div>
					</main>
				</div>
			)}
		</>
	);
}

export default Users;