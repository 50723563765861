import React from 'react';

// Components
import UserReportCards from '../../../../Components/UserReportCards/UserReportCards';
import UserReportTable from '../../../../Components/UserReportTable/UserReportTable';

// Styles
import './UserReportData.css'

function UserReportData({ data }: UserReportDataProps) {
    return (
        <div>
            <div className="user-detail-container">

                <h3>{data.betHistory.length > 0 ? data.betHistory[0].name ? data.betHistory[0].name : '' : ''}</h3>
            </div>

            <div className="user-detail-card-container">
                <UserReportCards
                    title={'Credited'}
                    value={data.exposure}
                />
                <UserReportCards
                    title={'Debited'}
                    value={data.pendingWithdrawal_sum}
                />
            </div>


            <div className="user-detail-card-container">
                <UserReportCards
                    title={'Commission'}
                    value={data.userGgr}
                />
                <UserReportCards
                    title={'Balance'}
                    value={data.balance}
                />
            </div>

            {/* <div className='user-detail-table-container'>
                <UserReportTable />
            </div> */}
        </div>
    )
}

export default UserReportData;

interface UserReportDataProps {
    data: any
}