import React from 'react';

// Styles
import './TableFilters.css';

function TableFiltersSearchBox({ value, placeholder, onChange, disabled }: TableFiltersSearchBoxProps) {
    return (
        <input
            disabled={disabled}
            className='table-filters-search-box'
            type='text'
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
        />
    )
}

export default TableFiltersSearchBox;

interface TableFiltersSearchBoxProps {
    placeholder: string,
    value?: any,
    onChange?: any
    disabled?:boolean
}
