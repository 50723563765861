import React, { useState, useEffect, useContext } from 'react'
import { User_Context } from '../../..//../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../..//../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../..//../utils/utility'
import { formatDate } from '../../..//../utils/utility'
import { Pagination, Stack } from "@mui/material";
import Sidenav from '../../../../Components/SideNavigation/SideNavigation';
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs';
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData'
import Stateful_Select from '../../../../Components/Dropdown/Dropdown';
import { useParams } from "react-router-dom";



interface Pldata {
    _id: string;
    type:string;
    userId:string;
    userName:string;
    reason:string;
    tag:string;
    amount:string
    updatedAt:string
}



function UserStatementList() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [UserStatementList, setUserStatementList] = useState<Pldata[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [totalUser, setTotalUser] = useState<number>(0)
    const { userId} = useParams();

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    useEffect(() => {
        getData()
    }, [currentPage, itemsPerPage]);

    const getData = () => {

        let data;
        if (startDate && endDate) {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                startDate,
                endDate
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemsPerPage: parseInt(itemsPerPage),
                _id:userId
                // filters: filters
            };
        }

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/masterFlow/getStatement`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setUserStatementList(response.data.data.payload.items)
                setTotalUser(response.data.data.payload.total)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }

    const clearDates = () => {
        setStartDate('')
        setEndDate('')
    }

  
    return (
        <div className="g-sidenav-show  bg-gray-100">
            <Sidenav />
            <main className="main-content position-relative">
                <div style={{ background: "#f8f9fa" }}>
                    <Breadcrumbs tab={"Statements"} />
                    <div className="container-fluid">
                    <div className="row">
                        <div className="row tp-form mb-1" style={{ display: 'flex' }}>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="From Date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl">To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="To Date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl"></label>
                                <a onClick={getData} className="sechBtn mt-1">
                                    Apply
                                </a>
                            </div>
                            <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                <label className="lbl"></label>
                                <a onClick={clearDates} className="sechBtn mt-1">
                                    clear dates
                                </a>
                            </div>

                            <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                                <label className="lbl">Items Per Page</label>
                                <Stateful_Select
                                    // label="Items Per Page"
                                    value={itemsPerPage.toString()}
                                    onChange={(newValue: any) => handlePerPage(newValue)}
                                    options={["10", "25", "50", "75", "100"]}
                                />
                            </div>
                        </div>
                        <div className="align-items-center mt-2 mb-2">
                        <label className="lbl"></label>
                        <b>Total count : {totalUser}</b>
                    </div>
                        
                    </div>
    
                    <div className="row">
                            <div className="row tp-form">
                                {/* <div className="col-6 col-xl-2 col-sm-4 pdrt mb-2">
                                    <Stateful_Select
                                        label="Items Per Page"
                                        value={itemsPerPage.toString()}
                                        onChange={(newValue: any) => handlePerPage(newValue)}
                                        options={["10", "25", "50", "75", "100"]}
                                    />
                                </div> */}
                            </div>
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Reason</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {UserStatementList?.map((item, index) => (
                                                <tr id={item._id}>
                                                    <td>{item?.userName}</td>
                                                    <td>{item?.reason}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>{item?.tag}</td>
                                                    <td>{item.updatedAt ? `${formatDate(item.updatedAt)} ${formatedTime(item.updatedAt)}` : ""}</td>
                                                    {/* <td>{item.updatedOn ? `${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}` : ""}</td> */}
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination d-flex justify-content-center">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPages}
                                            color="secondary"
                                            page={currentPage}
                                            onChange={(Event, New_Page) =>
                                                setCurrentPage(New_Page)
                                            }
                                        />
                                    </Stack>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default UserStatementList