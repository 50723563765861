import React, { useState, useEffect, useContext } from 'react'
import TableFiltersSearchBox from '../../../../Components/TableFilters/TableFiltersSearchBox'
import { API_Endpoint } from '../../../../Configuration/Settings'
import { User_Context } from '../../../../Contexts/User'
import { encryptData } from '../../../../utils/encryptData'
import { decryptData } from '../../../../utils/decryptData'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import TableFilterDatePicker from '../../../../Components/TableFilters/TableFilterDatePicker'

// Components
import Loader from '../../../../Components/Loader/Loader'
import { round } from 'lodash'
import { formatDate, formatedTime } from '../../../../utils/utility'


interface WacHistoryProps {
    data?: any,
    title?: String
}
function WacHistory({ data, title }: WacHistoryProps) {
    const [loading, setLoading] = useState(false)
    const [historyData, setHistoryData] = useState([]);
    const [transactionId, setTransactionId] = useState<string>("");
    const [gameRef, setGameRef] = useState<string>("");
    const [gameEvent, setGameEvent] = useState<string>("");
    const [roundId, setRoundId] = useState<string>("");
    const [amount, setAmount] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [commission, setCommission] = useState<string>("");
    const { User } = useContext(User_Context)
    const { User_ID } = useParams();


    const getWacHistory = async () => {
        setLoading(true)
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/bet-history`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData({ _id: User_ID, pageNo: 1, itemPerPage: 1 }) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const dataArray = response.data.data.payload.Completed.Wacs
            setHistoryData(dataArray);
            setLoading(false);
        }).catch((error) => { console.log(error); setLoading(false) });
    }


    useEffect(() => {
        getWacHistory();
    }, []);

    useEffect(() => { }, [historyData]);

    const onSearchTransaction = (values: any) => {
        setTransactionId(values);
        const filteredData = historyData.filter(
            (item: any) => item.transactionId.includes(values)
        );
        setHistoryData(filteredData);
    }
    const onSearchGameRef = (values: any) => {
        setGameRef(values);
        const filteredData = historyData.filter(
            (item: any) => item.gameReference.includes(values)
        );
        setHistoryData(filteredData);
    }
    const onSearchGameEvent = (values: any) => {
        setGameEvent(values);
        const filteredData = historyData.filter(
            (item: any) => item.winAmount.toString().includes(values)
        );
        setHistoryData(filteredData);
    }
    const onSearchRoundId = (values: any) => {
        setRoundId(values);
        const filteredData = historyData.filter(
            (item: any) => item.roundId.includes(values)
        );
        setHistoryData(filteredData);
    }
    const onSearchAmount = (values: any) => {
        setAmount(values);
        const filteredData = historyData.filter(
            (item: any) => item.amount.toString().includes(values)
        );
        setHistoryData(filteredData);
    }
    const onSearchStatus = (values: any) => {
        setStatus(values);
        const filteredData = historyData.filter(
            (item: any) => item.status.includes(values)
        );
        setHistoryData(filteredData);
    }

    const onUpdatedDateChange = (values: any) => {
        if (values) {
            const filteredData = historyData.filter(
                (item: any) => formatDate(item.updatedOn) === formatDate(values)
            );

            if (filteredData.length > 0) {
                setHistoryData(filteredData);
            }
        }

    }
    const onSearchCommission = (values: any) => {
        setCommission(values);
        const filteredData = historyData.filter(
            (item: any) => item.commissionAmount.toString().includes(values)
        );
        setHistoryData(filteredData);
    }

    return (
        <>
            {loading && <Loader />}
            <div className="row mt-4">
                {title && <h2>{title}</h2>}
                <div className="table-responsive">
                    <table className="table table-view">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Transaction Id</th>
                                <th>Game Reference</th>
                                <th>Winning Amount</th>
                                <th>Round Id</th>
                                <th>Bet Amount</th>
                                <th>Status</th>
                                <th>Result Date</th>
                                <th>Commission</th>
                            </tr>
                        </thead>
                        <thead >
                            <tr>
                                <th></th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={transactionId}
                                        placeholder={"search by transaction id"}
                                        onChange={(e: any) => onSearchTransaction(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={gameRef}
                                        placeholder={"search by game reference"}
                                        onChange={(e: any) => onSearchGameRef(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={gameEvent}
                                        placeholder={"search by winning amount"}
                                        onChange={(e: any) => onSearchGameEvent(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={roundId}
                                        placeholder={"search by round id"}
                                        onChange={(e: any) => onSearchRoundId(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={amount}
                                        placeholder={"search by amount"}
                                        onChange={(e: any) => onSearchAmount(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={status}
                                        placeholder={"search by status"}
                                        onChange={(e: any) => onSearchStatus(e.target.value)}
                                    />
                                </th>
                                <th>
                                    <TableFilterDatePicker
                                        onChange={(e: any) => onUpdatedDateChange(new Date(e.target.value))}
                                    />
                                </th>
                                <th>
                                    <TableFiltersSearchBox
                                        value={commission}
                                        placeholder={"search by commission"}
                                        onChange={(e: any) => onSearchCommission(e.target.value)}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((data: any, index: number) =>
                                <tr key={data._id}>
                                    <td>{index + 1}</td>
                                    <td>{data.transactionId}</td>
                                    <td>{data.gameReference}</td>
                                    <td>{data.winAmount}</td>
                                    <td>{data.roundId}</td>
                                    <td>{data.amount}</td>
                                    <td>{data.status === "W" ? "Win" : "Loss"}</td>
                                    <td>{`${formatDate(data.updatedOn)} ${formatedTime(data.updatedOn)}`}</td>
                                    <td>{data.commissionAmount}</td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default WacHistory